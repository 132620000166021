import { MillBonusesService } from './../bonuses/mill-bonuses.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { Balance, BalanceInfo, BalancesUpdatedEx, BalancesUpdatedExResponse, BrandCurrenciesResponse, BrandCurrency, ExecuteCheck, ExecuteCheckInput, ExecuteCheckResponse } from './mill-wallet.models';
import { MillBalanceService } from './balance/mill-balance.service';
import { MillAuthStateService } from '../auth/auth-state/mill-auth-state.service';
import { MillCryptoPaymentService } from './crypto-payment/mill-crypto-payment.service';
import { MillDepositService } from './deposit/mill-deposit.service';
import { MillWithdrawalService } from './withdraw/mill-withdraw.service';
import { CreateDeposit, CreateDepositInput, DepositMethod, DepositOption } from './deposit/mill-deposit.models';
import { CreateWithdrawal, WithdrawalOption } from './withdraw/mill-withdraw.models';
import { CryptoWalletAccount } from './crypto-payment/mill-crypto-payment.models';

const BRAND_CURRENCIES_QUERY = gql`
  query BrandCurrencies($brandId: ID!) {
    brandCurrencies(brandId: $brandId) {
      currencyCode
      enabled
    }
  }
`;


const EXECUTE_CHECK_MUTATION = gql`
  mutation ExecuteCheck($input: ExecuteCheckInput!) {
    executeCheck(input: $input) {
      check {
        ...Check
        __typename
      }
      __typename
    }
  }

  fragment Check on Check {
    action
    brandId
    createdAt
    details
    event
    eventRef
    id
    provider
    ref
    refUri
    risk
    status
    type
    updatedAt
    userId
    __typename
  }
`;

const BALANCE_SUBSCRIPTION = gql`
subscription BalancesUpdatedEx($userId: ID!) {
  balancesUpdatedEx(userId: $userId) {
    balances {
      amount
      balanceId
      currencyCode
      type
      userId
    }
  }
}
`;


@Injectable({
  providedIn: 'root',
})
export class MillWalletService {
  private balance = new BehaviorSubject<BalanceInfo>(null);

  constructor(
    private apollo: Apollo,
    private millBalalnceService: MillBalanceService,
    private millAuthStateService: MillAuthStateService,
    private millCryptoPaymentService: MillCryptoPaymentService,
    private millDepositService: MillDepositService,
    private millWithdrawalService: MillWithdrawalService,
    private millBonusesService: MillBonusesService

  ) {
    this.millAuthStateService.getAuthState().subscribe((authState) => {
      if (authState.isLogged) {
        this.millBalalnceService.fetchBalances(authState.accessToken, authState.userId).subscribe((balances) => {
          let balanseInfo: BalanceInfo = {
            balance: balances[0].amount,
            bonus_balance: 0,
            currency: balances[0].currencyCode
          };
          this.millBonusesService.getUserBonuses().subscribe((bonuses) => {
            console.log('Bonuses:', bonuses);
            balanseInfo.bonus_balance = bonuses[0]?.amount;
          });
          this.setBalance(balanseInfo);
        });

        apollo
          .use('mill')
          .subscribe<BalancesUpdatedExResponse>({
            query: BALANCE_SUBSCRIPTION,
            variables: {
              userId: authState.userId
            }
          }).subscribe((result) => {

            // this.millBonusesService.fetchBonuses(authState.accessToken, authState.userId).subscribe((bonuses) => {

            //   console.log('BalancesUpdatedEx:', result.data.balancesUpdatedEx.balances[0]);
            //   this.setBalance(result.data.balancesUpdatedEx.balances[0]);

            // });
            let balanseInfo: BalanceInfo = {
              balance: result[0].amount,
              bonus_balance: 0,
              currency: result[0].currencyCode
            };
            this.millBonusesService.getUserBonuses().subscribe((bonuses) => {
              console.log('Bonuses:', bonuses);
              balanseInfo.bonus_balance = bonuses[0]?.amount;
            });
            this.setBalance(balanseInfo);
          })
      }
    });
  }

  public fetchBrandCurrencies(brandId: string): Observable<BrandCurrency[]> {
    return this.apollo
      .use('mill')
      .query<BrandCurrenciesResponse>({
        query: BRAND_CURRENCIES_QUERY,
        variables: { brandId },
      })
      .pipe(
        take(1),
        map((response) => {
          const brandCurrencies = response.data?.brandCurrencies;
          console.log('BrandCurrencies response:', brandCurrencies);
          return brandCurrencies;
        })
      );
  }

  public executeCheck(iban: string, type: string): Observable<ExecuteCheck> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          const accessToken = authState.accessToken;
          const userId = authState.userId;
          return this.apollo
            .use('mill')
            .mutate<ExecuteCheckResponse>({
              mutation: EXECUTE_CHECK_MUTATION,
              variables: {
                input: {
                  iban: iban,
                  type: type,
                  userId: userId
                }
              },
              context: {
                headers: { Authorization: `Bearer ${accessToken}` },
              },
            })
            .pipe(
              take(1),
              map((response) => {
                const executeCheck = response.data?.executeCheck;
                console.log('ExecuteCheck response:', executeCheck);
                return executeCheck;
              })
            );
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }

  public getBalance(): Observable<BalanceInfo> {
    return this.balance.asObservable();
  }

  public setBalance(balance: BalanceInfo): void {
    this.balance.next(balance);
  }

  public clearBalance(): void {
    this.balance.next(null);
  }

  public cryptoDeposit(currencyCode: string, gateway: string, provider: string): Observable<CryptoWalletAccount> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          const accessToken = authState.accessToken;
          const userId = authState.userId;
          return this.millCryptoPaymentService.cryptoDeposit(accessToken, userId, currencyCode, gateway, provider);
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }

  public cryptoWithdrawal(input: any): Observable<CreateWithdrawal> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          const accessToken = authState.accessToken;
          const userId = authState.userId
          return this.millCryptoPaymentService.cryptoWithdrawal(accessToken, input);
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }

  public fetchBalances(): Observable<Balance[]> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          const accessToken = authState.accessToken;
          const userId = authState.userId;
          return this.millBalalnceService.fetchBalances(accessToken, userId);
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }

  public fetchDepositOptions(): Observable<DepositMethod[]> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          console.log('User is logged in', authState.accessToken);
          const accessToken = authState.accessToken;
          return this.millDepositService.fetchDepositOptions(accessToken);
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }

  public deposit(input: CreateDepositInput): Observable<CreateDeposit> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          const accessToken = authState.accessToken;
          const userId = authState.userId;
          return this.millDepositService.createDeposit(accessToken, input);
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }

  public fetchWithdrawalOptions(): Observable<WithdrawalOption[]> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          const accessToken = authState.accessToken;
          return this.millWithdrawalService.fetchWithdrawalOptions(accessToken);
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }

  public withdrawal(input: CreateWithdrawal): Observable<CreateWithdrawal> {
    return this.millAuthStateService.getAuthState().pipe(
      switchMap((authState) => {
        if (authState.isLogged) {
          const accessToken = authState.accessToken;
          const userId = authState.userId;
          return this.millWithdrawalService.createWithdrawal(accessToken, input);
        } else {
          console.error('User is not logged in');
          return of(null);
        }
      }));
  }
}
