import { MillAuthStateService } from 'src/app/services/auth/the-mill/auth/auth-state/mill-auth-state.service';
import { Component, EventEmitter, Inject, LOCALE_ID, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';


// import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise, take } from 'rxjs/operators';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { UserInfo } from 'src/app/services/auth/login/login.models';
import { MatDialog } from '@angular/material/dialog';
import { WrongPasswordDialogComponent } from './wrong-password/wrong-password-dialog/wrong-password-dialog.component';
import { DESKTOP_LOGIN, DialogConfig, FULL_DIALOG_CONFIG, MIX_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { lostPasswordTrx, signUpTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { Apollo, gql } from 'apollo-angular';
import { InAppPageParams } from 'src/app/apollo/models/base-models';
import { MillLoginEmailService } from 'src/app/services/auth/login/mill-login-email.service';
import { ErrorInfo } from 'src/app/services/auth/the-mill/auth/mill-auth.models';

const LOGIN_SEO_INFO = gql`query LoginSeoQuery($locale: Locale!){
  inAppPageParams(where: {siteName:"login"}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
    }
  }
 }`

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePageComponentWithDialogs {

  formSubmited: boolean = false;

  loginResponse: UserInfo;
  hidePassword = true;
  dialogSize: DialogConfig = this.detectDesktopService.isDesktop() ? DESKTOP_LOGIN : MIX_DIALOG_CONFIG;
  singnUrl: string = this.translationConfig.getTranslation(signUpTrx);
  lostPasswordUrl: string = this.translationConfig.getTranslation(lostPasswordTrx);
  desktopErrorText: string;
  isError: boolean = false;
  goBackUrl: string;
  loginForm = this.formBuilder.group({
    login: ['', [Validators.required]],
    password: ['', Validators.required]
  });

  @Output() closeEvent = new EventEmitter();

  closeDialog(url: string): void {
    this.closeEvent.next(url);
  }

  constructor(
    public dialog: MatDialog,
    protected millLoginService: MillLoginEmailService,
    protected millAuthStateService: MillAuthStateService,
    private apollo: Apollo,
    protected translationConfig: TranslationConfig,
    public formBuilder: UntypedFormBuilder,
    protected detectDesktopService: DetectDeviceService,
    public errorDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    protected licenceService: LicenceService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    super(errorDialog, detectDesktopService)
  }

  ngOnInit() {
    this.apollo
      .watchQuery<{ inAppPageParams: InAppPageParams }>({
        query: LOGIN_SEO_INFO,
        variables: {
          locale: this.locale,
        }
      })
      .valueChanges.pipe(
        take(1),
      ).subscribe((response) => {
        this.setSEOTags(response.data?.inAppPageParams?.seo?.metaTitle, response.data.inAppPageParams?.seo?.metaDescription, response.data.inAppPageParams?.seo?.relCanonical)
      });
  }

  onCreateAccountRequested() {
  }

  openLostPasswordDialog(msg?: string): void {
    const dialogRef = this.dialog.open(WrongPasswordDialogComponent, {
      ...this.dialogSize,
      panelClass: ["transparent", "destktop-reset-panel"],
      data: { msg: msg }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (dialogRef.componentInstance.redirectUrl) {
        this.closeDialog(dialogRef.componentInstance.redirectUrl);
      }
    });
  }


  onSubmit(loginForm) {
    if (this.formSubmited) return;
    this.formSubmited = true;
    this.millLoginService.requestLoginUser(loginForm).pipe(take(1)).subscribe((resp) => {
      if(resp.errors && resp.errors.length > 0) {
        this.formSubmited = false;
        this.millError(resp.errors);
        return;
      }
      this.millLoginService.requestLoginUser(loginForm).pipe(take(1)).subscribe((resp) => {
        this.loginResponse = resp.data;
        this.loginSuccessfull();
        this.isError = false;
      }, err => {
        console.log("Firebase login error", err);
        if(err.status === 406) {
          this.millAuthStateService.updateSessionGeolocation(false).pipe(take(1)).subscribe((res) => {
            console.log("Session geolocation updated",res);
            this.loginSuccessfull();
            this.isError = false;

          }
          );
        }
        this.formSubmited = false;
        this.desktopError(err);
      });
    }, err => {
      console.log("Mill login error", err);
      this.formSubmited = false;
      this.desktopError(err);
    });
  }

  moibleError(err) {
    console.log('err', err);

    if (err.status === 406) {
      if (err.error.remind_label && err.error.remind_label === "Reset password") {
        this.openLostPasswordDialog();
      }
      else {
        console.log(err.error.error_message)
        this.openErrorDialog(err.error.error_message, null);
      }
    }
  }

  desktopError(err) {
    console.log('err', err);
    if (err.status === 406) {
      if (err.error.remind_label && err.error.remind_label === "Reset password") {
        this.openLostPasswordDialog();
      }
      else {
        console.log(err.error.error_message)
        // this.openErrorDialog(err.error.error_message, null);
        this.desktopErrorText = err.error.error_message;
        this.isError = true;
      }
    }
  }

  millError(err: ErrorInfo[]) {
    console.log('err', err);
    if (err[0].message && err[0].message === "INVALID_CREDENTIALS") {
      this.openLostPasswordDialog();
    }
    else {
      let message = "";
      err.forEach(e => {
        message += e.message + ", ";
      });
      console.log(message)
      this.openErrorDialog(message, err[0].code);
    }
  }


  loginSuccessfull() {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        console.log('previous url', events[0].urlAfterRedirects);
        console.log('current url', events[1].urlAfterRedirects);
      });
    this.goBackUrl = this.route.snapshot.queryParams['redirectTo'];
    // this.closeDialog('../' + this.goBackUrl);
    if (this.goBackUrl)
      this.closeDialog(this.goBackUrl);
    else this.closeDialog('/')
  }

  signup() {
    this.closeDialog('../' + this.singnUrl);
  }

  lostPassword() {
    this.closeDialog('/' + this.lostPasswordUrl);
  }

}
