import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
//import { LoginStatusService } from '../services/auth/login/login-status.service';
import { MillAuthStateService } from '../services/auth/the-mill/auth/auth-state/mill-auth-state.service';

@Directive({
    selector: '[loggedHide]'
})
export class LoggedHideDirective implements OnInit {

    _isCreated: boolean = false;
    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        //private userInfoLoginService: LoginStatusService,
        private millAuthStateService: MillAuthStateService
    ) {

    }

    ngOnInit() {
        this.millAuthStateService.getLoginPartialStatus().subscribe((resp) => {
            if (resp) {
                this.viewContainer.clear();
            }
            else {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        })
    }
}
