import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { LoginStatusService } from '../login/login-status.service';
import { LoggedStatus, UserInfo } from '../login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { WINDOW } from '@ng-web-apis/common';
import { isPlatformServer } from '@angular/common';
import { MillAuthStateService } from '../the-mill/auth/auth-state/mill-auth-state.service';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  constructor(
    private faService: FirebaseAuthService,
    private loginStatusService: LoginStatusService,
    private millAuthStateService: MillAuthStateService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
    @Inject(PLATFORM_ID) public platformId,
    @Inject(WINDOW) readonly windowRef: Window
  ) {}

  private enableChatForLoggedUser(email: string, name: string) {
    // Placeholder for chat initialization logic
  }

  public disableChat() {
    const windowWrapper: any = this.windowRef;
    windowWrapper?.fcWidget?.hide();
  }

  public openChat() {
    const windowWrapper: any = this.windowRef;
    windowWrapper?.fcWidget?.show();
  }

  requestCheckUserInfo(): Observable<UserInfo> {
    if (isPlatformServer(this.platformId)) {
      this.millAuthStateService.updateAuthState(LoggedStatus.notLogged);
      this.loginStatusService.updateUserInfo({
        isLogged: LoggedStatus.notLogged,
      });
      return of({
        isLogged: LoggedStatus.notLogged,
        username: '',
        currency: '',
        fullInfo: null,
      });
    } else {
      return this.millAuthStateService.getAuthState().pipe(
        switchMap((state) => {
          if (state?.isLogged === LoggedStatus.logged) {
            this.enableChatForLoggedUser(state.login, state.login);
            return of({
              isLogged: LoggedStatus.logged,
              username: state.login,
              currency: 'EUR', // todob
              fullInfo: null,
            });
          } else {
            this.millAuthStateService.updateAuthState(LoggedStatus.notLogged);
            this.loginStatusService.updateUserInfo({
              isLogged: LoggedStatus.notLogged,
            });
            return of({
              isLogged: LoggedStatus.notLogged,
              username: '',
              currency: '',
              fullInfo: null,
            });
          }
        }),
        catchError((error: any) => {
          console.error('Error fetching user info', error);
          this.millAuthStateService.updateAuthState(LoggedStatus.notLogged);
          this.loginStatusService.updateUserInfo({
            isLogged: LoggedStatus.notLogged,
          });
          return of({
            isLogged: LoggedStatus.notLogged,
            username: '',
            currency: '',
            fullInfo: null,
          });
        })
      );
    }
  }
}
