<div class="page-wrapper">
  <div class="content-wrap">
    <app-desktop-category-menu [data]="categories" [showInput]="true" [setInput]="searchQuery" (searchQuery)="searchInput($event)"></app-desktop-category-menu>
    <div class="categoryNameContainer" fxLayout="row">
      <!-- <h2 fxLayoutAlign="center center" class="category-title"><img class="category-icon" src="assets/desktop/menu-search-icon.svg">{{categoryTitle}}</h2> -->
      <h2 fxLayoutAlign="center center" class="category-title"><img class="category-icon" src="assets/playbison/icon-search.png">{{categoryTitle}}</h2>
    </div>

  </div>
  <div class="game-card-wrapper">
    <ng-container *ngIf="result?.length>0 else nodata">
      <ng-container *ngFor="let item of result; let i=index">
        <div *ngIf="isLoggedIn || (!item.hideGame && !isLoggedIn)" class="game-card">
          <app-desktop-game-card (clickEvent)="openDialog($event)" [size]="size" [data]="item"></app-desktop-game-card>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #nodata>
  <div class="no-data" i18n>No results found</div>
</ng-template>