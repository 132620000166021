import { PopularGamesService } from './../../../services/profile/popular-games.service';
import { AfterViewInit, Component, effect, ElementRef, Inject, Input, PLATFORM_ID, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseGamePage } from '../../games/ase-game-page.component';
import { take } from 'rxjs/operators';
import { MatGridList } from '@angular/material/grid-list';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { GameItem } from 'src/app/apollo/models/base-models';
import SwiperCore, { Grid, SwiperOptions } from 'swiper';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { MostPlayedGameStatistic } from 'src/app/services/profile/profile.models';
import { isPlatformServer } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';

SwiperCore.use([Grid]);

@Component({
  selector: 'app-front-page-games',
  templateUrl: './front-page-games.component.html',
  styleUrls: ['./front-page-games.component.scss'],
})
export class FrontPageGamesComponent extends BaseGamePage implements AfterViewInit {

  static IDENTIFIER: string = 'games';
  private static MOBILE_GAMES_ROW = 3;
  private static DESTKTOP_GAMES_ROW = 4;

  mostPlayedGamesStatistics: MostPlayedGameStatistic[] = [];
  isServer: boolean;

  flexValue = 33;
  isDesktop: boolean = this.detectDeviceService.isDesktop();
  games: GameItem[];
  isLoggedIn = false;
  emptyElements: number[] = [];
  @ViewChild('gamegrid') gameGrid: MatGridList;
  @Input() isOutsideCategory: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() rowsCount = 3;
  private readonly responsiveColumns = {
    0: 3,
    374: 4,
  };

  // swiper
  @ViewChild('swiper', {static: false}) swiperElement: ElementRef;
  config: SwiperOptions = {
    grid: {
      rows: this.rowsCount,
      fill: 'column',
    },
    slidesPerView: 3,
    centerInsufficientSlides: true,
    spaceBetween: 10,
    lazy: true,
    preloadImages: false,
    centeredSlides: false,
  };

  constructor(
    public dialog: MatDialog,
    public detectDeviceService: DetectDeviceService,
    private loginStatusService: LoginStatusService,
    private popularGamesService: PopularGamesService,
    @Inject(PLATFORM_ID) private platformId,
    media: MediaMatcher,
    private renderer: Renderer2,
  ) {
    super(dialog);
    if (!isPlatformServer(this.platformId)) {
      this.isServer = false;
      effect(() => {
        this.mostPlayedGamesStatistics = this.popularGamesService.popularGames();
        this.addGamesStatistics();
      });
    } else {
      this.isServer = true;
    }
  }

  ngOnInit(): void {
    this.isDesktop = this.detectDeviceService.isDesktop();
    this.flexValue = this.isDesktop ? 25 : 33;
    const rowLength = this.isDesktop ? FrontPageGamesComponent.DESTKTOP_GAMES_ROW : FrontPageGamesComponent.MOBILE_GAMES_ROW;
    const numberOfGames = Math.min(this.data.game?.length || 0, this.data.numberGamesFrontPageMobile || Infinity);
    if (this.isServer || this.isDesktop) {
      this.games = (this.data.game as GameItem[])?.slice(0, numberOfGames > rowLength ? numberOfGames - (numberOfGames % rowLength) : numberOfGames);
    } else {
      this.games = (this.data.game as GameItem[]);
    }

    // Filter games
    this.games = this.games.filter((game) => (this.isLoggedIn || (!game.hideGame && !this.isLoggedIn)));

    this.addGamesStatistics();
    if (this.games.length < rowLength) {
      this.emptyElements = Array(rowLength - this.games.length).fill(0);
    }
    // debugger;
    this.loginStatusService.getIfUserLogged().pipe(take(1)).subscribe((isLogged) => {
      this.isLoggedIn = true;
    });

    this.config.grid.rows = this.rowsCount;
  }

  ngAfterViewInit(): void {
    if (!this.isServer) {
      const swiperWrapper = this.swiperElement['elementRef'].nativeElement.querySelector('.swiper-wrapper');
      if (swiperWrapper) {
        this.renderer.setStyle(swiperWrapper, 'max-height', `${this.rowsCount * 156}px`, RendererStyleFlags2.Important);
      }
    }
  }

  addGamesStatistics() {
    if (this.mostPlayedGamesStatistics.length > 0 && this.data && this.data.name === 'most played') {
      this.mostPlayedGamesStatistics.forEach((game) => {
        this.games.forEach((gameItem) => {
          if (gameItem.gameid === game.externalId) {
            gameItem.uniquePlayers = game.uniquePlayers * environment.uniquePlayerMultiplier;
          }
        });
      });
    }
  }
}
