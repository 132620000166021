import { MillAuthStateService } from 'src/app/services/auth/the-mill/auth/auth-state/mill-auth-state.service';
import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { GameStateService } from './services/profile/game-state.service';
import { Component, ElementRef, HostListener, Inject, Injector, LOCALE_ID, NgModuleRef, PLATFORM_ID, Renderer2, Type, ViewChild, ViewContainerRef, createNgModuleRef, effect, signal } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BasePageComponentWithDialogs } from './components/base-page/base-page.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationStart, Router, RouterEvent } from '@angular/router';
import { handleMessages } from './utils/bo-messages.handler';
import { delay, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { topMenuAction } from './components/top-menu/top-menu-button/top-menu-button.component';
import { LoginStatusService } from './services/auth/login/login-status.service';
import { LoggedStatus } from './services/auth/login/login.models';
import { PreloadService } from './services/utils/preload-on-start.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { DetectDeviceService } from './services/utils/detect-device.service';
import { MinimizeSiteService } from './services/utils/hide-footer.service';
import { OpenDesktopDrawerService } from './services/utils/open-desktop-drawer.service';
import { CookieService } from 'ngx-cookie-service';
import { InitialDataService } from './services/page/initial-data.service';
import { desktopTrx, loginTrx, signUpTrx } from './router-translation.labels';
import { LicenceService } from './services/utils/licence.service';
import { NavigationService } from './services/navigation/navigation.service';
import { GlobalNotificationsComponent } from './components/notifications/global-notifications/global-notifications.component';
import { TopGamesService } from "./services/profile/top-games.service";
import { environment } from 'src/environments/environment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificationService } from './services/notofications/notification.service';
import { AdformTrackingService } from './services/tracking/adform-tracking.service';
import { BonusListService } from './services/payment/bonus/bonus-list.service';
import { BonusLotteryService } from './services/profile/bonuslottery.service';
import Hotjar from '@hotjar/browser';
import { COUNTRY_ID, LICENCE } from './app-routing.module';

declare let gtag: Function;



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasePageComponentWithDialogs {
  title = 'casino-first';
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('mainbackground') mainBackground: ElementRef;
  @ViewChild('leftbackground') leftBackground: ElementRef;
  @ViewChild('rightbackground') rightBackground: ElementRef;

  TOP_MENU_IDENTIFIER: string = 'top_menu';

  loginDialogLink: string;
  isOpened: boolean = false;
  minimizePage: boolean = false;
  isMaintenance: boolean = false;
  isRegularPage: boolean;
  displayGamificationProgress: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  pixelTrackingUrl = signal(null);


  constructor(
    public navigation: NavigationService,
    dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private loginStatus: LoginStatusService,
    private millAuthStateService: MillAuthStateService,
    private preloadService: PreloadService,
    public detectDeviceService: DetectDeviceService,
    public matDialog: MatDialog,
    public minimizeSiteService: MinimizeSiteService,
    public openDrawerService: OpenDesktopDrawerService,
    private intialDataService: InitialDataService,
    private cookiesService: CookieService,
    protected licenceService: LicenceService,
    protected translationConfig: TranslationConfig,
    private snackBar: MatSnackBar,
    private gameStateService: GameStateService,
    private gamificationLevelService: GamificationLevelService,

    private adformService: AdformTrackingService,
    private bonusLotteryService: BonusLotteryService,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) protected locale,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LICENCE) private licence: string,
    private _renderer2: Renderer2,
    private _injector: Injector
  ) {
    effect(() => {
      this.displayGamificationProgress = gamificationLevelService.animateGamificationProgress();
    });
    super(dialog, detectDeviceService);
  }

  @ViewChild('globalNotifications', { static: false, read: ViewContainerRef })
  public globalNotificationsRef: ViewContainerRef;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    location.reload()
  }

  private _openLazyLoadedGlobalNotificationsComponent<T>(
    importedFile: T,
    componentToOpen: Type<GlobalNotificationsComponent>
  ): void {
    const module: Type<T> = (<any>importedFile)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModuleRef(module, this._injector);
    this.globalNotificationsRef.createComponent(componentToOpen, { ngModuleRef: moduleRef });
  }

  ngOnInit(): void {
    this.isRegularPage = true;
    if (!isPlatformServer(this.platformId)) {

      const siteId = 3920473;
      const hotjarVersion = 6;
      if (this.licence === 'bison_india' || this.locale.toLowerCase() === 'no' || this.locale.toLowerCase() === 'en-ca') {
        Hotjar.init(siteId, hotjarVersion);
      }
      this.router.events.pipe(
        filter((event) => event instanceof NavigationStart),
      ).subscribe(event => {

        if ((event as RouterEvent).url.startsWith('/' + desktopTrx)) {
          this.router.navigateByUrl((event as RouterEvent).url.substring(desktopTrx.length + 1))
        }
        // }
      })

      this.adformService.subscribeTrakcingPage().pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
        this.pixelTrackingUrl.set(url);
      })

      import('./modules/global-notifications/global-notifications.module').then((importedFile) => {
        const componentToOpen =
          importedFile.GlobalNotificationsModule.components.dynamicComponent;
        this._openLazyLoadedGlobalNotificationsComponent(importedFile, componentToOpen);
      });
    }

    this.minimizeSiteService.isRegularPage().pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), delay(10)).subscribe(resp => {
      this.isRegularPage = resp;
    });

    this.intialDataService.fetchData().pipe(take(1)).subscribe(resp => {
      this.isMaintenance = resp.maintenance.enabled;

      if (!isPlatformServer(this.platformId)) {
        resp.pageScripts.forEach(script => {
          if (!!script.script) {
            let scriptElement = this._renderer2.createElement('script');
            scriptElement.type = `text/javascript`;
            scriptElement.src = script.script;
            this._renderer2.appendChild(this._document.body, scriptElement);
          }
        })
      }
    })
    let title = '';
    if (!!this.licenceService.getKey("title-substitute")) {
      const title = this.licenceService.getKey("title-substitute");
      this.setSEOTags(title);
    }
    else {
      this.setSEOTags()
    }
    // this.meta.removeTag('description');
    this.cookiesService.set('firebase-country-override', this.locale);

    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      if (!!params && params['action']) {
        handleMessages(this, this.matDialog, params['value'], this.gameStateService, this.router, this.loginStatus, this.millAuthStateService, this.translationConfig);
        this.router.navigate([this.router.url.split('?')[0]])
      }
    })
    this.openDrawerService.getStateInitial().subscribe(
    )
    this.openDrawerService.getState().subscribe(

      resp => {
      }
    )
  }

  ngAfterViewInit(): void {
    if (!!this.sidenav) {
      this.sidenav.openedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = true
      )
      this.sidenav.closedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = false
      )
    }

    this.loginStatus.getLoginStatus().pipe(
      filter(status => status.isLogged === LoggedStatus.logged),
    ).subscribe((resp) => {
      if (this.licence === 'bison_india' || this.locale.toLowerCase() === 'no' || this.locale.toLowerCase() === 'en-ca') {
        this.preloadService.updateStore(resp.username);
        Hotjar.identify(resp.username, {
          email: resp.username,
        });
      }

      if (!isPlatformServer(this.platformId)) {
        // this.notificationService.fetchNotifications().pipe(filter(resp => !!resp), takeUntil(this.unsubscribe)).subscribe((resp) => {
        //   !!resp.notifications[0] && this.snackBar.open(resp.notifications[0].text, 'X',
        //     {
        //       horizontalPosition: this.horizontalPosition,
        //       verticalPosition: this.verticalPosition,
        //       duration: 10000
        //     });
        //   this.notificationService.setNotificationSeen();



        // })

      }

    });

    if (!isPlatformServer(this.platformId)) this.preloadImages();
  }


  private preloadImages() {
    // this.preloadImagesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
    //   if (!!url) {
    //     let img = new Image();
    //     img.src = url;
    //   }

    // })
  }

  topClicked(value: topMenuAction) {
    if (value === topMenuAction.TOP) {
      this.sidenav.open();
    }
    else if (value === topMenuAction.BACK) {
      const returnUrl = (this.navigation.subjectPrevUrl.value.includes(this.translationConfig.getTranslation(signUpTrx)) || this.navigation.subjectPrevUrl.value.includes(this.translationConfig.getTranslation(loginTrx))) ? '/' : this.navigation.subjectPrevUrl.value;
      this.router.navigateByUrl(returnUrl);
    }
  }

  close() {
    this.sidenav.close();
  }
}
