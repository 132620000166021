export function loadChatScript(language, email, firstName) {
  let buttonId = '';
  switch (language) {
    case 'en':
      buttonId = 'jbrk30zv';
      break;
    case 'de':
      buttonId = 'zca9ju2f';
      break;
    case 'pl':
      buttonId = 'm87w5x63';
      break;
    case 'de-AT':
      buttonId = 'i9f1yi63';
      break;
    case 'hu':
      buttonId = 'xu2f4mf8';
      break;
    case 'hi':
    case 'en-IN':
      buttonId = 'qmju87b2';
      break;
    default:
      buttonId = 'jbrk30zv';
      break;
  }

  const body = document.body as HTMLDivElement;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
    (function(d, src, c) {
      var t = d.scripts[d.scripts.length - 1],
        s = d.createElement('script');
      s.id = 'la_x2s6df8d';
      s.defer = true;
      s.src = src;
      s.onload = s.onreadystatechange = function() {
        var rs = this.readyState;
        if (rs && (rs != 'complete') && (rs != 'loaded')) {
          return;
        }
        c(this);
      };
      t.parentElement.insertBefore(s, t.nextSibling);
    })(document, 'https://playbison.ladesk.com/scripts/track.js', function(e) {
      LiveAgent.createButton('${buttonId}', e);
      LiveAgent.clearAllUserDetails();
      LiveAgent.setUserDetails('${email}', '${firstName}');
    });
  `;
  body.appendChild(script);
}
