import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { LostPasswordResponse } from './lost-password.models';
import { LOST_PASSWORD, NEW_PASSWORD } from '../../http/base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from 'src/app/app-routing.module';


@Injectable({
  providedIn: 'root'
})
export class LostPasswordService {

  constructor(http:HttpClient,cookie:CookieService,@Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
   }


  requestLostPasswordinUser (lostPasswordForm: UntypedFormGroup): Observable<LostPasswordResponse> {
    // return super.postRequest<LostPasswordResponse>(LOST_PASSWORD,lostPasswordForm)
    // .pipe(
    //   catchError(this.handleError)
    // );
    return null as any;
  }

  setNewPassword(newPasswordForm:UntypedFormGroup): Observable<any> {
    // return super.postRequest<any>(NEW_PASSWORD,newPasswordForm)
    // .pipe(
    //   catchError(this.handleError)
    // );
    return null as any;
  }
}
