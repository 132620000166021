<div class="desktop-reset">
    <h1 i18n>Login failed</h1>
    <div class="desktop-hint">
        <div i18n>Don't worry :)</div>
        <div i18n>Resetting your password only takes a few seconds.</div>
    </div>
    <div class="buttons" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" >
        <button mat-flat-button color="warn" (click)="lostPassword()" type='button' i18n>new password</button>
        <button mat-stroked-button color="primary" (click)="contactSupport()" type='button' i18n>contact support</button>
    </div>
</div>