import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BasePageComponent } from '../../base-page/base-page.component';
import { inboxTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { Router } from '@angular/router';
import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { ProfileInfoComponent } from '../../profile/profile-info/profile-info.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BasePageComponent {
  inboxUrl: string = this.translationConfig.getTranslation(inboxTrx);
  isLogin: boolean = false;
  gamificationIndex: number = -1;
  isDesktop: boolean;

  constructor(public detectDeviceService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    protected licenceService: LicenceService,
    private gamificationLevelService: GamificationLevelService,
    protected loginStatus: LoginStatusService,
    private router: Router,
  ) { super() }


  ngOnInit(): void {
    this.isDesktop = this.detectDeviceService.isDesktop();
    this.loginStatus.getLoginStatus().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.isLogin = resp.isLogged === LoggedStatus.logged ? true : false;
    });

    this.gamificationLevelService.getGamificationFromStore().pipe(takeUntil(this.unsubscribe)).subscribe((gamification) => {
      this.gamificationIndex = ProfileInfoComponent.getLemonIconNameIndex(gamification?.levelNumber);
    })
  }


  redirectToProfile(): void {
    this.router.navigate(['', 'client-area', 'main']);
  }

}


