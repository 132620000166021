import { MillBonusesService } from './../../auth/the-mill/bonuses/mill-bonuses.service';
import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { BackofficeBonuses, BonusInfo, BonusListResponse } from './bonus.models';
import { concatMap, filter, map, switchMap, take, toArray } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, defaultIfEmpty, from, Observable } from 'rxjs';
import { BO_BONUS_INFO } from '../../http/base-http.service';
import { HOST_NAME } from 'src/app/app-routing.module';
import { ClonerService } from '../../utils/clone-object.service';
import { log } from 'console';
import { UserBonusConnection } from '../../auth/the-mill/bonuses/mill-bonuses.models';

const query = gql`query BonusList($locale: Locale!){
  bonusList(locales: [$locale, en],where: {name: "deposit"}) {
    bonuses {
      name
      displayName
      teaserText
      externalId
      isFreeSpin
      highlightBonus
      pagesNew{
        name
      }
      thumbnail {
        url
      }
    }
  }
  }
  `
@Injectable({
  providedIn: 'root'
})
export class BonusListService {

  constructor(private apollo: Apollo, private cloneService: ClonerService, http: HttpClient, cookie: CookieService, private millBonusesService: MillBonusesService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) { }

  private store = new BehaviorSubject<BonusInfo[]>([]);

  updateStore() {
    let cmsList$ = this.apollo
      .watchQuery<BonusListResponse>({
        query: query,
        variables: {
          locale: this.locale
        }
      })
      .valueChanges;
    //let boList$ = this.get<BackofficeBonuses>(BO_BONUS_INFO);

    let millList$ = this.millBonusesService.getUserBonuses();

    millList$.pipe(
      switchMap((millResp) => {
        console.log('millRespwithbonuses', millResp);
        let mutableMillResp = this.cloneService.deepClone<UserBonusConnection>(millResp);
        return cmsList$.pipe(
          take(1),
          switchMap(cmsResp => from(cmsResp.data.bonusList.bonuses)),
          filter(item => {
            console.log('item2222', item.externalId, mutableMillResp);
            const hasMatch = mutableMillResp?.edges.filter(b => b.node.bonusId === item.externalId).length > 0;
            const isDefaultBonus = item.externalId === '0' || item.externalId === '-2';

            console.log('item:', item, 'hasMatch:', hasMatch, 'isDefaultBonus:', isDefaultBonus);

              return mutableMillResp.edges.filter(b => b.node.bonusId === item.externalId).length > 0 || item.externalId === '0' || item.externalId === "-2"
          }),
          map(item => {
            console.log('item333', item);

            let mutableItem = this.cloneService.deepClone<BonusInfo>(item);
            //const millMethod = millResp.userBonusConnection?.edges?.filter(b => b.node.bonusId.toString() === item.externalId)[0];

            // mutableItem.excludedOperators = millMethod?.excluded_operators;
            // mutableItem.includedOperators = millMethod?.included_operators;

            return mutableItem;
          })
        )
      }
      ), toArray()
    ).subscribe({
      next: resp => {
        const sorted = resp.sort((a, b) => (a.externalId > b.externalId) ? 1 : -1);
        this.store.next(sorted)
      },
      error: err => console.log(err)
    })

  }


  public fetchData(): Observable<BonusInfo[]> {
    if (this.store.value.length === 0) this.updateStore();
    return this.store.asObservable();
  }

  public getFromStore(): Observable<BonusInfo[]> {
    return this.store.asObservable().pipe(defaultIfEmpty([]));
  }
  // .filter(([product, category]) => {
  //   return product.cat_id.some(id => id === category.id);
  // })
  // .map(([product, category]) => product);

  // }
}
