import { resumeGameTrx } from './../router-translation.labels';
import { GameStateService } from '../services/profile/game-state.service';
import { MatDialog } from '@angular/material/dialog';
import { BasePageComponentWithDialogs } from '../components/base-page/base-page.component';
import { DESKTOP_SMALL, TEST_SUCCESS_DIALOG_CONFIG, DialogConfig } from '../components/dialog/dialog.config';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { Router } from '@angular/router';
import { LoginStatusService } from '../services/auth/login/login-status.service';
import { TranslationConfig } from './translate-config';
import { MillAuthStateService } from '../services/auth/the-mill/auth/auth-state/mill-auth-state.service';

const paymentsuccess: string = $localize`:@@paymemntSuccess:We have successfully received your payment. Deposit is added to your balance.`;
const paymentsuccesstitle: string = $localize`:@@paymemntSuccessTitle:Payment successful`;
const paymentfail: string = $localize`:@@paymemntFail:Sorry, there was a problem accepting your payment. Please try again later or contact our support.`;
const paymentfailtitle: string = $localize`:@@paymemntFailTitle:Payment declined`;
const paymentPending: string = $localize`:@@paymentPending:Your payment is pending. Please check your balance soon.`;
const paymentPendingTitle: string = $localize`:@@paymentPendingTitle:Payment pending`;
const paymentAborted: string = $localize`:@@paymentAborted:Your payment was aborted. Please try again.`;
const paymentAbortedTitle: string = $localize`:@@paymentAbortedTitle:Payment aborted`;
const messages = {
  "ok": {
    isError: false,
    value: paymentsuccess,
    title: paymentsuccesstitle,
    component: PaymentConfirmationComponent
  },
  "pending": {
    isError: false,
    value: paymentPending,
    title: paymentPendingTitle,
    component: PaymentConfirmationComponent
  },
  "processing": {
    isError: false,
    value: paymentPending,
    title: paymentPendingTitle,
    component: PaymentConfirmationComponent
  },
  "fail": {
    isError: true,
    value: paymentfail,
    title: paymentfailtitle
  },
  "abort": {
    isError: true,
    value: paymentAborted,
    title: paymentAbortedTitle
  },
}

export const handleMessages = (
  component: BasePageComponentWithDialogs,
  matDialog: MatDialog,
  actionValue: string,
  gameStateService: GameStateService,
  router: Router,
  loginStatus: LoginStatusService,
  millAuthStateService: MillAuthStateService,
  translationConfig: TranslationConfig
) => {
  let entry = null;

  for (const [key, value] of Object.entries(messages)) {
    if (actionValue.startsWith(key)) {
      entry = value;
    }
  }
  let isError = false;
  let message = "";
  let title = ""
  console.log('router.url', router.url);

  millAuthStateService.getIfUserLogged().subscribe(async (isLogged) => {
    if (isLogged && router.url.split('?')[0] === '/') {
      gameStateService.isDataLodaed.next(false);
      gameStateService.refreshGameState().then(() => {
        gameStateService.getLastGameState().subscribe((gameState) => {
          if (gameStateService.isDataLodaed.value) {
            if (entry) {
              isError = entry.isError;
              message = entry.value;
              title = entry.title
              if (gameState && gameState.timestamp >= new Date().getTime() - 1000 * 60 * 10) {
                if (isError) {
                  component.openErrorDialog(message, title, () => {
                    router.navigate([translationConfig.getTranslation(resumeGameTrx), gameState.name], {
                      skipLocationChange: true
                    })
                  });
                }
                else {
                  component.openSuccessDialog(message, title, () => {
                    router.navigate([translationConfig.getTranslation(resumeGameTrx), gameState.name], {
                      skipLocationChange: true
                    })
                  });
                }
              } else {
                if (isError) {
                  component.openErrorDialog(message, title);
                } else {
                  if (entry.component) {
                    //const size: DialogConfig = DESKTOP_SMALL;
                    const size: DialogConfig = TEST_SUCCESS_DIALOG_CONFIG;
                    matDialog.open(entry.component, {
                      ...size,
                      backdropClass: 'blur-backdrop',
                      //panelClass: ["transparent", "alert-panel"],
                      panelClass: ["transparent", "dialog-card-wrapper"],
                      data: { msg: message, title: title, dataRefToConfirm: {} },
                    }
                    );
                  }
                  else {
                    component.openSuccessDialog(message, title);
                  }
                }
              }
            }
            entry = null;
          }
        });
      });
    }
  });
}
