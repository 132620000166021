import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer, observeOn, asyncScheduler, Subscription } from 'rxjs';
import { finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JACKPOT_VALUES } from '../http/base-http.service';
import { JackpotDisplay } from './jackpot.models';
import { environment } from 'src/environments/environment';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'any'
})
export class JackpotService implements OnDestroy {
  private unsubscribe = new Subject<void>();
  jackpots = new BehaviorSubject<JackpotDisplay[]>(null);
  wallet: Observable<any>;
  constructor(
    private http: HttpClient,
    @Inject(HOST_NAME) public hostName,
    private ngZone: NgZone,
  ) {
  }

  // apiUrl = environment.production ? environment.uiBackend + this.hostName.replace('cache.', '') : environment.uiBackend; todob
  apiUrl = "https://prizeengine.playbison.com";

  createHeaders(): HttpHeaders {
    const newHeaders = {};
    newHeaders['Content-Type'] = 'text/plain';
    return new HttpHeaders(newHeaders);
  }

  getActiveJackpots() {
    const httpOptionsDefault = {
      headers: this.createHeaders(),
      withCredentials: true,
      params: new HttpParams().set('t', new Date().getTime().toString())
    };
    console.log('getActiveJackpotsURL--------', `${this.apiUrl}`);

    return this.http.get<any>(`${this.apiUrl}${JACKPOT_VALUES}`, { ...httpOptionsDefault }).pipe(
      observeOn(asyncScheduler),
      take(1),
      map((resp) => {
        resp.sort((a, b) => a.currentValue >= b.currentValue ? -1 : 1)
        return resp
      }
      )
    )
  }

  setJackpots(jackpots: JackpotDisplay[]) {
    this.jackpots.next(jackpots);
  }



  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
