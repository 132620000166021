import { Component, Inject, LOCALE_ID } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs';
import { InAppPageParams } from 'src/app/apollo/models/base-models';
import { HeadManipulateService } from 'src/app/services/utils/head-manipulate.service';
import { ServerSideResponseService } from 'src/app/services/utils/not-found-service';
import { BasePageComponent } from '../base-page/base-page.component';
import { Router } from '@angular/router';

export const NOT_FOUND_META = gql`query inAppMetaTags($locale: Locale!){
  inAppPageParams(where: {siteName:"404"}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
    }
  }
}`;
@Component({
  selector: 'app-mobile-not-found',
  templateUrl: './mobile-not-found.component.html',
  styleUrls: ['./mobile-not-found.component.scss']
})
export class MobileNotFoundComponent extends BasePageComponent {

  constructor(protected ssr: ServerSideResponseService,
    protected manipulateHeaderService: HeadManipulateService,
    @Inject(LOCALE_ID) public locale: string,
    protected apollo: Apollo,
    protected router: Router
  ) {
    super();
    this.ssr.setNotFound();
    const url = decodeURIComponent(this.router.url);

        if (url !== this.router.url)
        {
            this.router.navigateByUrl(url);
        }
  }

  protected setTags() {
    const query = NOT_FOUND_META;
    this.apollo
      .watchQuery<{
        inAppPageParams: InAppPageParams
      }>({
        query,
        variables: {
          locale: this.locale,
        }
      })
      .valueChanges.pipe(
        take(1),
      ).subscribe((response) => {
        this.setSEOTags(response.data.inAppPageParams?.seo?.metaTitle, response.data.inAppPageParams?.seo?.metaDescription, response.data.inAppPageParams?.seo?.relCanonical)
        this.manipulateHeaderService.removeLinks();

      });
  }

  ngOnInit(): void {
    this.setTags();
  }

}
