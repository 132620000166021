import { MillWalletService } from 'src/app/services/auth/the-mill/wallet/mill-wallet.service';
import { Component, Input, NgZone } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { Subscription, takeUntil, timer } from 'rxjs';
import { depositTrx, paymentTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';
import { Balance, BalanceInfo } from 'src/app/services/auth/the-mill/wallet/mill-wallet.models';

@Component({
  selector: 'app-balance-user',
  templateUrl: './balance-user.component.html',
  styleUrls: ['./balance-user.component.scss']
})
export class BalanceUserComponent extends BasePageComponent {

  paymentUrl: string[] = [this.translationConfig.getTranslation(paymentTrx), this.translationConfig.getTranslation(depositTrx)];


  balanceInfo: BalanceInfo;
  constructor(
    public detectDeviceService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    protected licenceService: LicenceService,
    protected _ngZone: NgZone,
    private millWalletService: MillWalletService
  ) { super() }

  @Input() isGetFromStore: boolean = false;
  timerSubscription$: Subscription;

  ngOnInit(): void {
    this.millWalletService.getBalance().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(balance => {
      this.balanceInfo = balance;
    });
  }

  ngOnDestroy(): void {
  }

}
