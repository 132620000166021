import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HOST_NAME } from 'src/app/app-routing.module';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class BaseFirebaseService {

  constructor(
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    @Inject(HOST_NAME) public hostName
  ) { }

  //apiUrl = environment.production ? environment.uiBackend + this.hostName.replace('cache.', '') : environment.uiBackend; todob
  apiUrl = "https://prizeengine.playbison.com";


  public setApiUrl(apiUrl?: string) {
    console.log('ccc111:', this.apiUrl,this.hostName );
    this.apiUrl = apiUrl ? apiUrl : environment.production ? environment.uiBackend + this.hostName.replace('cache.', '') : environment.uiBackend;
    console.log('ccc222:', this.apiUrl,this.hostName );

  }

  get<T>(url: string, withCredentials: boolean = true): Observable<T> {
    return this.afAuth.idToken.pipe(
      take(1),
      switchMap((token) => {
        const httpOptionsDefault = {
          headers: token ? { "X-Firebase-ID-Token": token } : {},
          withCredentials: withCredentials,
          params: new HttpParams().set('t', new Date().getTime().toString())
        };
        console.log('httpOptionsDefault:', this.apiUrl,this.hostName );

        return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })
      })
    )
  }

  protected getRequestHttpParams<T>(url: string, reqParams: HttpParams) {
    // const args = { params: reqParams };
    return this.afAuth.idToken.pipe(
      take(1),
      switchMap((token) => {
        const httpOptionsDefault = {
          params: reqParams,
          headers: token ? { "X-Firebase-ID-Token": token } : {},
          withCredentials: true
        };
        return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })
      })
    )
  }

  post<T1, T2>(url: string, request: T1, withCredentials: boolean = true) {
    return this.afAuth.idToken.pipe(
      switchMap((token) => {
        const httpOptionsDefault = {
          headers: token ? { "X-Firebase-ID-Token": token } : {},
          withCredentials: withCredentials
        };
        console.log('httpOptionsDefault123:', this.apiUrl,this.hostName );
        return this.http.post<T2>(this.apiUrl + url, request, { ...httpOptionsDefault });
      })
    )
  }

  getDocumentDB<T>(path: string) {
    return this.afs.doc<T>(path).valueChanges();
  }

  getSnapshotDocument<T>(path: string) {
    return this.afs.doc<T>(path).snapshotChanges();
  }

  addDocument<T>(document: T, path: string) {
    return this.afs.doc<T>(path).update(document)
  }

  setDocument<T>(document: T, path: string, isMerge: boolean) {
    return this.afs.doc<T>(path).set(document, { merge: isMerge })
  }
}
