import { MillSession, UpdateSessionGeolocation } from './../mill-auth.models';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, distinctUntilChanged, takeWhile, takeLast } from 'rxjs/operators';
import { MillAuthService } from '../mill-auth.service';
import { LoggedStatus, UserData, UserLoginDataResult, UserRegistrationPayload, RefreshToken } from '../mill-auth.models';
import * as CryptoJS from 'crypto-js';
import { AuthState } from './mill-auth-state.models';
import { log } from 'console';

@Injectable({
  providedIn: 'root',
})
export class MillAuthStateService {

  private refreshInProgress = false;
  private _partialLoginNotifier: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private authState = new BehaviorSubject<AuthState | null>(this.loadAuthStateFromLocalStorage());

  constructor(private millAuthService: MillAuthService) { }

  getAuthState(): Observable<AuthState | null> {
    return this.authState.asObservable();
  }

  registerUser(payload: UserRegistrationPayload): Observable<AuthState> {
    return this.millAuthService.registerUser(payload).pipe(
      takeLast(1),
      switchMap((verifyWithAuthResult) => {
        if (!verifyWithAuthResult?.data) {
          console.error('No access token received in the response:', verifyWithAuthResult);
          return throwError(() => new Error('Registration failed: No access token received'));
        }

        const { userId, accessToken, expiresAt, refreshToken } = verifyWithAuthResult.data;

        return this.fetchUserData(userId, accessToken).pipe(
          take(1),
          map((userData) => {
            if (!userData) {
              throw new Error('User data fetch failed');
            }
            const authState: AuthState = {
              login: userData.email,
              userId,
              isLogged: LoggedStatus.logged,
              accessToken,
              expiresAt,
              refreshToken,
            };
            this.setAuthState(authState);
            return authState;
          }),
          catchError((error) => {
            console.error('Error fetching user data:', error);
            return throwError(() => new Error('Failed to fetch user data'));
          })
        );
      }),
      catchError((error) => {
        console.error('Error during registration or validation:', error);
        return throwError(() => new Error('Registration failed'));
      })
    );



  }

  loginUser(email: string, password: string): Observable<UserLoginDataResult> {
    return this.millAuthService.loginUser(email, password).pipe(
      tap((loginData) => {
        if (!loginData) {
          throw new Error('Login failed: No login data received');
        }
        this.setAuthState({
          login: email,
          userId: loginData.data.userId,
          isLogged: LoggedStatus.logged,
          accessToken: loginData.data.accessToken,
          expiresAt: loginData.data.expiresAt,
          refreshToken: loginData.data.refreshToken,
        });
      }),
      catchError(error => {
        console.error('Error during login:', error);
        return throwError(() => new Error('Login failed'));
      })
    );
  }

  logoutUserByCreds(userId: string, accessToken: string): Observable<void> {
    return this.millAuthService.logoutUser(userId, accessToken).pipe(
      tap(() => {
        this.clearAuthState();
      }),
      catchError(error => {
        console.error('Error during logout:', error);
        return throwError(() => new Error('Logout failed'));
      })
    );
  }

  logoutUser(): Observable<void> {
    const currentState = this.authState.value;

    if (!currentState) {
      return throwError(() => new Error('No auth state found'));
    }

    return this.logoutUserByCreds(currentState.userId, currentState.accessToken);
  }

  fetchUserData(userId: string, accessToken: string): Observable<UserData> {
    return this.millAuthService.fetchUserData(userId, accessToken).pipe(
      catchError(error => {
        console.error('Error fetching user data:', error);
        return throwError(() => new Error('Failed to fetch user data'));
      })
    );
  }

  refreshTokenIfNeeded(): Observable<string> {
    const currentState = this.authState.value;

    if (!currentState) {
      return throwError(() => new Error('No auth state found'));
    }

    console.log('Checking token expiration...', currentState);


    if (this.isTokenExpired(currentState.expiresAt)) {
      if (this.refreshInProgress) {
        return this.getAuthState().pipe(
          switchMap(newState => (newState ? of(newState.accessToken) : throwError(() => new Error('Failed to refresh token')))),
          catchError(error => {
            console.error('Error during token refresh while already in progress:', error);
            return throwError(() => new Error('Failed to refresh token'));
          })
        );
      }

      this.refreshInProgress = true;
      console.log('Refreshing token...');

      return this.millAuthService.refreshToken(currentState.refreshToken).pipe(
        tap(newAccessToken => {
          const authState: AuthState = {
            login: currentState.login,
            userId: currentState.userId,
            isLogged: LoggedStatus.logged,
            accessToken: newAccessToken.accessToken,
            expiresAt: newAccessToken.expiresAt,
            refreshToken: newAccessToken.refreshToken,
          };
          this.setAuthState(authState);
          console.log('Token refreshed:', authState, newAccessToken.accessToken);

          this.refreshInProgress = false;
        }),
        map(newAccessToken => newAccessToken.accessToken),
        catchError(error => {
          this.refreshInProgress = false;
          this.authState.value.isLogged = LoggedStatus.notLogged;
          localStorage.removeItem('authState');
          console.error('Error during token refresh:', error);
          return throwError(() => new Error('Failed to refresh token'));
        })
      );
    } else {
      return of(currentState.accessToken);
    }
  }

  private isTokenExpired(expiresAt: string): boolean {
    const expiryDate = new Date(expiresAt).getTime();
    const now = Date.now();
    return now >= expiryDate;
  }

  public setAuthState(authData: AuthState | null): void {
    console.log('Setting auth state:', authData);

    if (authData) {
      try {

        const encryptedAuthData = CryptoJS.AES.encrypt(JSON.stringify(authData), 'mill').toString();
        localStorage.setItem('authState', encryptedAuthData);
      } catch (error) {
        console.error('Error encrypting auth state:', error);
      }
    } else {
      localStorage.removeItem('authState');
    }
    console.log('authData', authData);
    this.setLoginPartialStatus(authData?.isLogged === LoggedStatus.logged);
    this.authState.next(authData);
  }

  private loadAuthStateFromLocalStorage(): AuthState | null {
    const storedAuthState = localStorage.getItem('authState');
    console.log('Stored auth state:', storedAuthState);

    if (storedAuthState) {
      try {

        const bytes = CryptoJS.AES.decrypt(storedAuthState, 'mill');
        const decryptedAuthState = bytes.toString(CryptoJS.enc.Utf8);
        console.log('Decrypted auth state:', JSON.parse(decryptedAuthState));

        let res = JSON.parse(decryptedAuthState);
        this.setLoginPartialStatus(res.isLogged === LoggedStatus.logged);
        return res;
      } catch (e) {
        console.error('Error decrypting auth state:', e);
        localStorage.removeItem('authState');
        return null;
      }
    }
    return null;
  }

  private clearAuthState(): void {
    console.log('Clearing auth state...');

    localStorage.removeItem('authState');
    this.authState.next(null);
  }

  public updateAuthState(isLogged: LoggedStatus): void {
    const currentState = this.authState.value;

    console.log('Updating auth state:', isLogged, currentState);


    if (!currentState) {
      this.setAuthState({
        login: '',
        userId: '',
        isLogged,
      });
    } else {
      this.setAuthState({
        ...currentState,
        isLogged,
      });
    }
  }

  public getIfUserLogged(): Observable<AuthState> {
    return this.authState.asObservable().pipe(
      filter(authState => authState?.isLogged === LoggedStatus.logged),
      distinctUntilChanged()
    );
  }

  public getLoginPartialStatus(): Observable<boolean> {
    console.log('Getting partial login status...');

    return this._partialLoginNotifier.asObservable().pipe(
      filter((resp) => resp !== null)
    );
  }

  public setLoginPartialStatus(status: boolean): void {
    console.log('Setting partial login status:', status);

    this._partialLoginNotifier.next(status);
  }

  public getLoginStatus(includeVoid = true): Observable<AuthState> {
    return this.authState.asObservable().pipe(
      filter(authState => includeVoid ? true : authState?.isLogged !== LoggedStatus.voidState),
      distinctUntilChanged()
    );
  }

  public updateSessionGeolocation(allow: boolean): Observable<MillSession> {
    return this.authState.asObservable().pipe(
      filter(authState => authState?.isLogged === LoggedStatus.logged),
      take(1),
      switchMap((authState) => {
        return this.millAuthService.updateSessionGeolocation(allow, authState.accessToken).pipe(
          map((response) => {
            return response;
          })
        );
      })
  );
}

}
