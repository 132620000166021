import { Apollo } from 'apollo-angular';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HOST_NAME } from '../../app-routing.module';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoggedStatus } from '../auth/login/login.models';
import { Avatar, avatarQuery } from 'src/app/components/profile/edit-profile/select-avatar/select-avatar.component';
import { FirebaseAuthService } from '../auth/firebase/firebase-auth.service';
import { log } from 'console';


@Injectable({
    providedIn: 'root'
})
export class AvatarService extends BaseFirebaseService {
    private unsubscribe: Subject<void> = new Subject();
    constructor(
        http: HttpClient,
        afAuth: AngularFireAuth,
        afs: AngularFirestore,
        private apollo: Apollo,
        private loginStatus: LoginStatusService,
        private injector: Injector,
        @Inject(HOST_NAME) public hostName
    ) {
        super(http, afAuth, afs, hostName);
    }

    setAvatar(url: string) {
        this.loginStatus.getLoginStatus().pipe(
            filter((status) => status.isLogged === LoggedStatus.logged),
            switchMap((status) => combineLatest([of(status), this.setDocument<{ avatar: string }>({ avatar: url }, `prize-users/${status.username}`, true)])),
            switchMap(([status, resp]) => {
                const fAuthService = this.injector.get(FirebaseAuthService);
                return fAuthService.updateUserDatabase(status?.username)
            }),
            take(2)
        ).subscribe(() => {
            console.log("avater set")
        })
    }

    getAvatar(): Observable<string> {
        return this.loginStatus.getIfUserLogged().pipe(
            switchMap(() => {
                return this.loginStatus.getUserState().pipe(
                    // filter(userData => !!userData.dbUser?.avatar),
                    map((userData) => {
                      console.log('userData AVATAR', userData)
                        return userData.dbUser?.avatar || 'https://media.graphassets.com/z7Cevc7PS0WNntSmC1cv';
                    })
                )
            }
            )
        )
    }

    public setRandomAvatar() {
        // console.log("setting random avatar")
        // return this.getCMSAvatars().pipe(
        //     map(avatars => {
        //         const avatar = avatars[Math.floor(Math.random() * avatars.length)];
        //         return avatar.thumbnail.url;
        //     }),
        //     map(avatar => {
        //         this.setAvatar(avatar);
        //         return avatar
        //     }),
        //     take(1)
        // )
    }

    getCMSAvatars(): Observable<Avatar[]> {
        return this.apollo
            .watchQuery<{ avatars: Avatar[] }>({
                query: avatarQuery,
            })
            .valueChanges.pipe(
                take(1),
                map(resp => resp.data.avatars)
            );
    }

}
