import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { bonusQueueTrx, prizeBoxTrx, redeemPromocodeTrx } from 'src/app/router-translation.labels';
import { BonusLotteryWheelComponent } from './bonus-lottery-wheel/bonus-lottery-wheel.component';
import { MinimizePageResolver } from 'src/app/resolvers/minimize-page.resolver';
import { PrizeBoxesComponent } from './prize-boxes/prize-boxes.component';
import { MainClientAreaComponent } from './main-client-area/main-client-area.component';

const routes: Routes = [
  { path: 'main', component: MainClientAreaComponent },
  { path: 'lottery-wheel', resolve: { minimize: MinimizePageResolver }, component: BonusLotteryWheelComponent },
  { path: prizeBoxTrx, resolve: { minimize: MinimizePageResolver }, component: PrizeBoxesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientAreaRoutingModule { }
