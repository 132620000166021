import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID, NgZone } from '@angular/core';
import { AngularFirestore, CollectionReference, DocumentData, Query } from '@angular/fire/compat/firestore';
import { LoginStatusService } from '../auth/login/login-status.service';
import { delay, filter, map, mergeMap, switchMap, take, catchError } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, timer } from 'rxjs';
import { LoggedStatus } from '../auth/login/login.models';
import { NotificationDB, NotificationTemplate, Notification, Notificationinfo, GlobalNotification } from './notifications.models';

const query = gql`query Notification($locale: Locale!,$names:[String!]){
  notifications(locales: [$locale, en],where: {name_in: $names}) {
      name
      titleText
      thumbnail {
        url
      }
      isUserNeedClose
  }
}
`

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationQueue = new BehaviorSubject<GlobalNotification | Notification>(null);
  notification = new BehaviorSubject<GlobalNotification>(null);

  constructor(
    private db: AngularFirestore,
    private apollo: Apollo,
    private loginStatusService: LoginStatusService,
    @Inject(LOCALE_ID) public locale: string,
    private ngZone: NgZone
  ) {
    this.startCheckingForUnreadMessages();
  }

  public startCheckingForUnreadMessages() {
    /// FOR DEBUG
    // const notification: Notification = {
    //   text: "winner takes it all",
    //   createdAt: new Date(),
    //   thumbnail: { url: "" },
    //   isSeen: false,
    //   messageTemplate: "JACKPOT_WIN_PERSONAL",
    //   isShowAsGlobal: true,
    // }
    // timer(1000).subscribe(() => {
    //   this.notificationQueue.next(notification);

    // })

    this.ngZone.runOutsideAngular(async () => {
      await this.fetchGlobalNotifications()
        .subscribe((notification) => {
          this.notification.next(notification);
          this.notificationQueue.next(notification);
        });
      await this.fetchNotifications()
        .subscribe((notifications) => {
          if (notifications && notifications.notifications && notifications.notifications.length > 0) {
            for (let notification of notifications.notifications) {
              this.notificationQueue.next(notification);
            }
          }
        });
    })
  }

  private fillTemplate(templateString, templateVars) {
    return new Function("return `" + templateString + "`;").call(templateVars);
  }


  fetchGlobalNotifications() {
    return this.loginStatusService.getUserState().pipe(
      filter(user => user !== undefined && !!user.dbUser),
      switchMap((user) => {
        return this.loginStatusService.getLoginStatus();
      }),
      switchMap((user) => {
        return this.db.collection<NotificationDB>('global-notifications', ref => {
          let query: CollectionReference | Query<DocumentData> = ref;
          query = query.orderBy('createdAt', "desc")
          return query
        }).valueChanges()
          .pipe(
            map(items => {
              const currentDate = new Date()
              return items.map(item => {
                const createdDate: Date = new Date(item.createdAt.toDate());
                let validityDate = new Date(createdDate.setSeconds(createdDate.getSeconds() + item.validity));
                return validityDate >= currentDate ? item : null
              })
            }),
            mergeMap((notificationDB: NotificationDB[]) => {
              notificationDB = notificationDB.filter(value => !!value)
              const names = new Set(notificationDB.map(value => value.messageId))
              return combineLatest({
                notificationDB: of(notificationDB),
                notificationsCMS: this.apollo.watchQuery<{ notifications: NotificationTemplate[] }>({
                  query: query,
                  variables: {
                    names: [...names],
                    locale: this.locale
                  }
                }).valueChanges
              }).pipe(
                delay(0),
                map((resp) => {
                  const notifications: GlobalNotification[] = resp.notificationDB.map((itemDB) => {
                    const itemCMS = resp.notificationsCMS.data.notifications.find(i => i.name == itemDB.messageId);
                    if (!itemCMS) {
                      return null;
                    }
                    const notification: GlobalNotification = {
                      text: this.fillTemplate(itemCMS.titleText, itemDB.params),
                      createdAt: itemDB.createdAt,
                      thumbnail: itemCMS.thumbnail,
                      validity: itemDB.validity,
                      messageTemplate: itemDB.messageTemplate,
                      isGlobal: true
                    }

                    return notification;
                  }).filter(item => !!item)
                  return notifications[0];
                }),
                filter(item => !!item)
              )
            })
          )
      })
    )
  }

  fetchNotifications(): Observable<Notificationinfo> {

    return this.loginStatusService.getUserState().pipe(
      filter(user => user !== undefined && !!user.dbUser),
      switchMap((user) => {
        return this.loginStatusService.getLoginStatus();
      }),
      switchMap((user) => {
        return this.db.collection('prize-users').doc(user.username).collection<NotificationDB>('notifications', ref => {
          let query: CollectionReference | Query<DocumentData> = ref;
          query = query.where("isSeen", "==", false).orderBy('createdAt', "desc").limit(2)
          return query
        }).valueChanges()
      }),
      mergeMap((notificationDB: NotificationDB[]) => {
        const names = new Set(notificationDB.map(value => value.messageId))
        if (names.size > 0) {
          return combineLatest(of(notificationDB), this.apollo.watchQuery<{ notifications: NotificationTemplate[] }>({
            query: query,
            variables: {
              names: [...names],
              locale: this.locale
            }
          }).valueChanges.pipe(
            catchError(error => {
              console.error('Failed to fetch notifications from CMS', error);
              return of({ data: { notifications: [] } });
            })
          )).pipe(
            delay(0),
            map(([notificationDB, notificationsCMS]) => {
              let notifications: Notification[] = notificationDB.map((itemDB) => {
                const itemCMS = notificationsCMS.data.notifications.find(i => i.name == itemDB.messageId);
                if (itemCMS === undefined && !itemDB.isNotToShow) return null;
                const notification: Notification = {
                  text: itemDB.isNotToShow ? '' : this.fillTemplate(itemCMS.titleText, itemDB.params),
                  createdAt: itemDB.createdAt,
                  thumbnail: itemCMS?.thumbnail || '',
                  isSeen: itemDB.isSeen,
                  messageTemplate: itemDB.messageTemplate,
                  isShowAsGlobal: itemDB.isShowAsGlobal,
                  isNotToShow: itemDB.isNotToShow,
                  isUserNeedClose: itemCMS?.isUserNeedClose
                }
                return notification;
              }).filter(item => !!item)
              const notificationInfo: Notificationinfo = {
                notifications: notifications,
                numberTotal: notifications.length,
                numberNotSeen: notifications.filter(i => !i?.isSeen).length
              }
              return notificationInfo
            })
          )
        } else {
          return of(null)
        }
      })
    )
  }

  sendNotification(mesageId: String) {
    this.loginStatusService.getIfUserLogged().pipe(
      take(1),
    ).subscribe((user) => {
      let doc = this.db.collection('prize-users').doc(user.username).collection('notifications');
      doc.add({
        createdAt: new Date(),
        isSeen: false,
        messageId: mesageId,
      })
    })
  }

  setNotificationSeen() {
    return this.loginStatusService.getLoginStatus().pipe(
      filter(user => user !== undefined && user.isLogged === LoggedStatus.logged),
      switchMap((user) => {
        return this.db.collection('prize-users').doc(user.username).collection('notifications', ref => {
          let query: CollectionReference | Query<DocumentData> = ref;
          query = query.where("isSeen", "==", false)
          return query
        }).snapshotChanges()
      }),
      take(1),
      mergeMap(clients => clients),
    ).subscribe(doc => {
      const docRef = doc.payload.doc.ref;
      docRef.delete();
    })
  }

  ngOnDestroy(): void {
    this.notificationQueue.complete();
    this.notification.complete();
  }
}
