<div *ngIf="!isSubmited" class="lost-password-body center" fxLayoutAdivgn="center center" [class]="'desktop-lost-title'">
    <h1 class="page-header">{{page?.header}}
    </h1>
    <div [hidden]="lostPasswordResponse">
        <p class="top-text">{{body}}</p>
    </div>
</div>
<div *ngIf="!isSubmited;else desktopCompleted" [ngClass]="isDesktop===true?'desktop':'mobile'" class="casino-form-wrapper">
    <div [id]="'desktop-casino-form'">
        <mat-error *ngIf="hasError">{{errorMsg}}</mat-error>
        <div class="form-wrap">
            <form name="lostPasswordForm" [formGroup]="lostPasswordForm" (ngSubmit)="onSubmit(lostPasswordForm)">
                <mat-form-field [appearance]="'outline'" class="form-width">
                    <mat-label i18n>email address</mat-label>
                    <input matInput type="text" placeholder="{{enterEmail}}" formControlName="mail">
                    <img class="img-suffix" src="{{licenceService.getKey('icon-email')}}" matSuffix>
                    <mat-error *ngIf="lostPasswordForm.get('mail').hasError('email')" i18n>please provide valid email address.</mat-error>
                </mat-form-field>
                <div class="buttons">
                    <div>
                        <button class="lost-btn" mat-flat-button color="warn" type="submit" i18n>submit</button>
                    </div>
                </div>
                <div class="bottom-text">{{bodyLast}}</div>
            </form>
        </div>
    </div>
</div>
<ng-template #desktopCompleted>
    <div class="desktop-result" [ngClass]="isDesktop===true?'desktop':'mobile'" fxLayout="column" fxLayoutAlign="center center">
        <div class="info">
            <p i18n>Recovery email has been sent.</p>
            <p i18n>Now check your email to reset your password. See you soon!</p>
        </div>
        <img src="{{licenceService.getKey('icon-sent-email')}}">
        <button round-button filled="filled" color="warn" type="button" i18n (click)="closeDialog()">ok</button>
    </div>
</ng-template>