import { Injectable } from '@angular/core';
import { Observable, of, catchError, throwError } from 'rxjs';
import { concatMap, delay, expand, map, mergeMap, switchMap, take, takeWhile } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import {
  BrandCountriesResponse,
  BrandCountry,
  BrandCurrency,
  ErrorInfo,
  RefreshToken,
  RefreshTokenResponse,
  UserData,
  UserDataResponse,
  UserExistsResponse,
  UserLoginDataResult,
  UserLoginPayload,
  UserLoginResponse,
  UserRegistrationPayload,
  UserRegistrationResponse,
  VerifyUserPayload,
  VerifyUserResponse,
  VerifyWithAuthResult,
  BrandCurrenciesResponse,
  UpdateSessionGeolocationInput,
  UpdateSessionGeolocationResponse,
  MillSession
} from './mill-auth.models';
import { Session } from 'protractor';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        __typename
      }
      __typename
    }
  }
`;

const VERIFY_USER_MUTATION = gql`
  mutation VerifyWithAuth($input: VerifyWithAuthInput!) {
    verifyWithAuth(input: $input) {
      accessToken
      expiresAt
      refreshToken
      sessionId
      userId
      __typename
    }
  }
`;

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      accessToken
      expiresAt
      refreshToken
      __typename
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      expiresAt
      refreshToken
      sessionId
      userId
      createdAt
      __typename
    }
  }
`;

const USER_EXISTS_QUERY = gql`
  query UserExists($input: UserExistsInput!) {
    userExists(input: $input)
  }
`;

const LOGOUT_MUTATION = gql`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      sessionId
    }
  }
`;

const GET_USER_DATA_QUERY = gql`
  query User($userId: ID!) {
    user(userId: $userId) {
      address1
      address2
      birthDate
      city
      countryCode
      email
      firstName
      gender
      id
      brandId
      isEmailVerified
      isPhoneVerified
      jurisdiction
      kycProfile {
        ibanVerifiedAt
        pep
        pepCheckedAt
        sanctioned
        sanctionedCheckedAt
        schufaKycVerifiedAt
        sumsubKycVerifiedAt
        verified
        __typename
      }
      lastName
      phone
      postcode
      receiveEmail
      receivePhone
      receiveSms
      receiveSnail
      state
      __typename
    }
  }
`;

const GET_BRAND_COUNTRIES_QUERY = gql`
query BrandCountries($brandId: ID!) {
  brandCountries(brandId: $brandId, enabled: true) {
    country {
      name
      dialCode
      code
      id
    }
    countryCode
    currencyCode
    enabled
  }
}
`;

const GET_BRAND_CURRENCIES_QUERY = gql`
query BrandCurrencies($brandId: ID!) {
  brandCurrencies(brandId: $brandId, enabled: true) {
    currencyCode
    default
    enabled
    isCrypto
    marketingFxRate
    id
    brandCurrencyId
  }
}
`;

const UPDATE_SESSION_GEO_MUTATION = gql`
  mutation UpdateSessionGeolocation($input: UpdateSessionGeolocationInput!) {
  updateSessionGeolocation(input: $input) {
    session {
      allowedGeolocation
      id
      ip
      provider
      sessionId
      status
    }
  }
}
`;

@Injectable({
  providedIn: 'root',
})
export class MillAuthService {
  constructor(private apollo: Apollo) { }

  public registerUser(payload: UserRegistrationPayload): Observable<VerifyWithAuthResult> {
    payload.brandId = environment.millBrandId;

    return this.apollo
      .use('mill')
      .mutate<UserRegistrationResponse>({
        mutation: CREATE_USER_MUTATION,
        variables: { input: payload },
      })
      .pipe(
        take(1),
        concatMap((createResponse) => {
          console.log('CreateUser response:', createResponse);

          const userId = createResponse.data?.createUser?.user?.id;
          if (!userId) {
            return throwError(() => new Error('User creation failed: No userId returned'));
          }

          const verifyPayload: VerifyUserPayload = {
            brandId: environment.millBrandId,
            code: userId.slice(0, 8),
          };

          return of(null).pipe(
            delay(1000), // Задержка перед первой попыткой
            expand(() =>
              this.apollo
                .use('mill')
                .mutate<VerifyUserResponse>({
                  mutation: VERIFY_USER_MUTATION,
                  variables: { input: verifyPayload },
                })
                .pipe(
                  take(1),
                  map((verifyResponse) => {
                    const verifyWithAuth = verifyResponse.data?.verifyWithAuth;
                    if (verifyWithAuth) {
                      console.log('Verification successful:', verifyWithAuth);
                      return {
                        data: verifyWithAuth,
                        errors: [],
                      } as VerifyWithAuthResult;
                    } else {
                      console.warn('Verification failed, retrying...');
                      throw new Error('INVALID_CODE');
                    }
                  }),
                  catchError((error) => {
                    if (error.message === 'INVALID_CODE') {
                      console.log('Retrying verification due to INVALID_CODE...');
                      return of(null).pipe(delay(2000)); // Повторная попытка через 2 секунды
                    }
                    console.error('Non-retryable error during verification:', error);
                    return throwError(() => error);
                  })
                )
            ),
            takeWhile((result) => !result?.data, true), // Продолжаем, пока data не станет доступным
            catchError((error) => {
              console.error('Final verification error:', error);
              return of({
                data: null,
                errors: [{ message: 'Verification failed', code: 'VERIFICATION_FAILED' }],
              } as VerifyWithAuthResult);
            })
          );
        }),
        catchError((error) => {
          console.error('Error during registration:', error.message);
          return of({
            data: null,
            errors: [{ message: error.message, code: error.extensions?.code || 'UNKNOWN_ERROR' }],
          } as VerifyWithAuthResult);
        })
      );
  }



  public loginUser(email: string, password: string): Observable<UserLoginDataResult> {

    const payload: UserLoginPayload = {
      password: password,
      username: email,
      brandId: environment.millBrandId
    }

    return this.apollo
      .use('mill')
      .mutate<UserLoginResponse>({
        mutation: LOGIN_MUTATION,
        variables: { input: payload },
      })
      .pipe(
        take(1),
        map((response) => {
          const loginResult = response.data?.login;
          console.log('Login response:', loginResult);

          if (loginResult) {
            return {
              data: loginResult,
              errors: []
            } as UserLoginDataResult;
          } else {
            return {
              data: null,
              errors: [{ message: 'Login failed', code: 'LOGIN_FAILED' }]
            } as UserLoginDataResult;
          }
        }),
        catchError((error) => {
          console.log('Error:', error);
          return of({
            data: null,
            errors: [{ message: error.message, code: error.extensions?.code }]
          } as UserLoginDataResult);
        })
      );
  }


  public refreshToken(refreshToken: string): Observable<RefreshToken> {
    return this.apollo
      .use('mill')
      .mutate<RefreshTokenResponse>({
        mutation: REFRESH_TOKEN_MUTATION,
        variables: { input: { refreshToken } },
      })
      .pipe(
        take(1),
        map((response) => {
          const refreshToken = response.data.refreshToken;
          console.log('RefreshToken response:', refreshToken);
          return refreshToken;
        })
      );
  }

  public checkUserExists(email: string): Observable<boolean> {
    return this.apollo
      .use('mill')
      .query<UserExistsResponse>({
        query: USER_EXISTS_QUERY,
        variables: { input: { brandId: environment.millBrandId, email } },
      })
      .pipe(
        take(1),
        map((response) => {
          const exists = response.data.userExists;
          console.log('UserExists response:', exists);
          return exists;
        })
      );
  }

  public logoutUser(userId: string, accessToken: string): Observable<void> {
    return this.apollo
      .use('mill')
      .mutate({
        mutation: LOGOUT_MUTATION,
        variables: { input: { userId, reason: 'USER' } },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map(() => {
          console.log('Logout response:', userId);
        })
      );
  }

  public fetchUserData(userId: string, accessToken: string): Observable<UserData> {
    return this.apollo
      .use('mill')
      .query<UserDataResponse>({
        query: GET_USER_DATA_QUERY,
        variables: { userId },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map((response) => {
          const userData = response.data?.user;
          console.log('UserData response:', userData);
          return userData;
        })
      );
  }

  private parseGraphQLErrors(message: string): ErrorInfo[] {
    const errors: ErrorInfo[] = [];
    const regex = /Variable ".*" got invalid value .* at "input\.(\w+)"; Expected type "[^"]+".*`(\w+)` cannot represent value:/g;
    let match;
    while ((match = regex.exec(message)) !== null) {
      errors.push({
        message: `Invalid value - ${match[2]}`,
        code: 'INVALID_VALUE'
      });
    }
    return errors;
  }

  public getBrandCountries(): Observable<BrandCountry[]> {
    return this.apollo
      .use('mill')
      .query<BrandCountriesResponse> ({
        query: GET_BRAND_COUNTRIES_QUERY,
        variables: { brandId: environment.millBrandId  },
      })
      .pipe(
        take(1),
        map((response) => {
          const brandCountries = response.data?.brandCountries;
          console.log('BrandCountries response:', brandCountries);
          return brandCountries;
        })
      );
  }

  public getBrandCurrencies(): Observable<BrandCurrency[]> {
    return this.apollo
      .use('mill')
      .query<BrandCurrenciesResponse>({
        query: GET_BRAND_CURRENCIES_QUERY,
        variables: { brandId: environment.millBrandId },
      })
      .pipe(
        take(1),
        map((response) => {
          const brandCurrencies = response.data?.brandCurrencies;
          console.log('BrandCurrencies response:', brandCurrencies);
          return brandCurrencies;
        })
      );
  }

  public updateSessionGeolocation(allowed: boolean, accessToken: string): Observable<MillSession> {
    const input: UpdateSessionGeolocationInput = {
      allowedGeolocation: allowed,
    };
    return this.apollo
      .use('mill')
      .mutate<UpdateSessionGeolocationResponse> ({
        mutation: UPDATE_SESSION_GEO_MUTATION,
        variables: { input },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map((res) => {
          console.log('UpdateSessionGeolocation response:', res.data?.updateSessionGeolocation.session
          );
          return res.data?.updateSessionGeolocation.session;
        })
      );
  }
}
