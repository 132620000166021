import { UserInfo } from '../../login/login.models';
export interface UserRegistrationPayload {
  brandId: string;
  password: string;
  address1?: string;
  address2?: string;
  birthDate?: string;
  city?: string;
  countryCode: string;
  email: string;
  firstName: string;
  gender?: string;
  lastName: string;
  phone: string;
  postcode?: string;
  receiveEmail?: boolean;
  receivePhone?: boolean;
  receiveSms?: boolean;
  receiveSnail?: boolean;
  state?: string;
  redirectUri?: string;
}

export interface RegisteredUser {
  id: string;
  __typename: string;
}

export interface UserRegistrationResponse {
  createUser: {
    user: RegisteredUser;
    __typename: string;
  };
}

export interface VerifyUserPayload {
  brandId: string;
  code: string;
}

export interface VerifyWithAuthResult extends BaseMillResponse<VerifyWithAuth> {
  [x: string]: any;
}

export interface VerifyUserResponse {
  verifyWithAuth: VerifyWithAuth;
}

export interface VerifyWithAuth {
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  sessionId: string;
  userId: string;
  __typename: string;
}

export interface UserLoginPayload {
  brandId: string;
  username: string;
  password: string;
}

export interface ErrorInfo {
  message: string;
  code: string;
}

export interface UserInfoResult extends BaseMillResponse<UserInfo> { }

export interface BaseMillResponse<T> {
  data: T;
  errors?: ErrorInfo[];
}

export interface UserLoginResponse {
  login: UserLoginData
}

export interface UserLoginData {
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  sessionId: string;
  userId: string;
  createdAt: string;
  __typename: string;
}

export interface UserLoginDataResult extends BaseMillResponse<UserLoginData> { }

export interface RefreshTokenResponse {
  refreshToken: RefreshToken;
}

export interface RefreshToken {
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  __typename: string;
}

export interface UserExistsResponse {
  userExists: boolean;
}

export interface UserData {
  address1: string;
  address2: string;
  birthDate: string;
  city: string;
  countryCode: string;
  email: string;
  firstName: string;
  gender: string;
  id: string;
  brandId: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  jurisdiction: string;
  kycProfile: KycProfile;
  lastName: string;
  phone: string;
  postcode: string;
  receiveEmail: boolean;
  receivePhone: boolean;
  receiveSms: boolean;
  receiveSnail: boolean;
  state: string;
  __typename: string;
}

export interface KycProfile {
  ibanVerifiedAt: string;
  pep: string;
  pepCheckedAt: string;
  sanctioned: string;
  sanctionedCheckedAt: string;
  schufaKycVerifiedAt: string;
  sumsubKycVerifiedAt: string;
  verified: boolean;
  __typename: string;
}

export interface UserDataResponse {
  user: UserData;
}

export enum LoggedStatus {
  voidState = 0,
  notLogged = -1,
  logged = 1,
  changePassword = 2
}

export interface BrandCountry {
  country: Country;
  countryCode: string;
  currencyCode: string;
  enabled: boolean;
}

export interface Country{
  name: string;
  dialCode: number;
  code: string;
  id: string;
};

export interface BrandCountriesResponse {
  brandCountries: BrandCountry[];
}

export interface BrandCurrency {
  currencyCode: string;
  default: boolean;
  enabled: boolean;
  isCrypto: boolean;
  marketingFxRate: number;
  id: string;
  brandCurrencyId: string;
}

export interface BrandCurrenciesResponse {
  brandCurrencies: BrandCurrency[];
}

export interface UpdateSessionGeolocationInput {
  allowedGeolocation: boolean;
}

export interface UpdateSessionGeolocationResponse {
  updateSessionGeolocation: UpdateSessionGeolocation;
}

export interface UpdateSessionGeolocation {
  session: MillSession;
}

export interface MillSession {
  allowedGeolocation: boolean;
  id: string;
  ip: string;
  provider: string;
  sessionId: string;
  status: string;
}

