import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { CreatePayment, CreatePaymentResponse, CryptoWalletAccount, CryptoWalletAccountResponse } from './mill-crypto-payment.models';

const CRYPTO_DEPOSIT_QUERY = gql`
  query CryptoWalletAccount($currencyCode: CurrencyCode!, $gateway: PaymentGateway!, $provider: PaymentProvider!, $userId: ID!) {
    cryptoWalletAccount(
      currencyCode: $currencyCode
      gateway: $gateway
      provider: $provider
      userId: $userId
    ) {
      id
      label
      __typename
    }
  }
`;

const CRYPTO_WITHDRAWAL_QUERY = gql`
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      payment {
        errorMessage
        id
        status
        statusCode
        statusMessage
        __typename
      }
      redirect {
        html
        iframeable
        uri
        __typename
      }
      __typename
    }
  }
`;


@Injectable({
  providedIn: 'root',
})
export class MillCryptoPaymentService {
  constructor(private apollo: Apollo) { }

  public cryptoDeposit(accessToken: string, userId: string, currencyCode: string, gateway: string, provider: string): Observable<CryptoWalletAccount> {
    return this.apollo
      .use('mill')
      .query<CryptoWalletAccountResponse>({
        query: CRYPTO_DEPOSIT_QUERY,
        variables: { currencyCode, gateway, provider, userId },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map((response) => {
          const cryptoWalletAccount = response.data?.cryptoWalletAccount;
          console.log('CryptoWalletAccount response:', cryptoWalletAccount);
          return cryptoWalletAccount;
        })
      );
  }

  public cryptoWithdrawal(accessToken: string, input: any): Observable<CreatePayment> {
    return this.apollo
      .use('mill')
      .mutate<CreatePaymentResponse>({
        mutation: CRYPTO_WITHDRAWAL_QUERY,
        variables: { input },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map((response) => {
          const createPayment = response.data?.createPayment;
          console.log('CreatePayment response:', createPayment);
          return createPayment;
        })
      );
  }
}
