import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { Balance, BalancesResponse } from './mill-balance.models';

const BALANCES_QUERY = gql`
  query Balances($userId: ID!, $vertical: Vertical) {
    balances(userId: $userId, vertical: $vertical) {
      amount
      currencyCode
      id: balanceId
      type
      __typename
    }
  }
`;


@Injectable({
  providedIn: 'root',
})
export class MillBalanceService {
  constructor(private apollo: Apollo) { }

  public fetchBalances(accessToken: string, userId: string, vertical?: string): Observable<Balance[]> {
    return this.apollo
      .use('mill')
      .query<BalancesResponse>({
        query: BALANCES_QUERY,
        variables: { userId, vertical },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map((response) => {
          const balances = response.data?.balances;
          console.log('Balances response:', balances);
          return balances;
        })
      );
  }
}

