import { MillAuthStateService } from '../the-mill/auth/auth-state/mill-auth-state.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { MillAuthService } from '../the-mill/auth/mill-auth.service';
import { LoggedStatus, UserInfoResult,  } from '../the-mill/auth/mill-auth.models';

@Injectable({
  providedIn: 'root'
})
export class MillLoginEmailService {

  constructor(
    private millAuthStateService: MillAuthStateService,
    private millAuthService: MillAuthService
  ) {}

  requestLoginUser(loginForm: UntypedFormGroup): Observable<UserInfoResult> {
    console.log('loginForm123', loginForm);

    return this.millAuthService.loginUser(loginForm.get('login')?.value, loginForm.get('password')?.value)
    .pipe(
      switchMap((login) => {
        console.log('login123', login);

        if (login.data) {
          const newState = {
            login: loginForm.get('login')?.value,
            userId: login.data?.userId,
            isLogged: LoggedStatus.logged,
            accessToken: login.data?.accessToken,
            expiresAt: login.data?.expiresAt,
            refreshToken: login.data?.refreshToken
          };
          this.millAuthStateService.setAuthState(newState);
          return of({
            data: newState,
            errors: []
          });
        } else {
          this.millAuthStateService.updateAuthState(LoggedStatus.notLogged);
          return of({
            data: null,
            errors: login.errors
          });
        }
        return of(null);
      })
    );
  }
}
