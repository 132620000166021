import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { CreateWithdrawal, CreateWithdrawalResponse, WithdrawalOption, WithdrawalOptionResponse } from './mill-withdraw.models';

const PAYMENT_OPTIONS_QUERY = gql`
  query WithdrawalOptions($type: PaymentType!) {
    paymentOptions(type: $type) {
      accounts {
        id
        label
        __typename
      }
      currencyCode
      defaultAmount
      fixedFee
      gateway
      max
      method
      min
      percentFee
      provider
      quickOptions
      type
      __typename
    }
  }
`;


const CREATE_PAYMENT_MUTATION = gql`
  mutation CreateWithdrawal($input: CreateWithdrawalInput!) {
    createPayment(input: $input) {
      payment {
        errorMessage
        id
        status
        statusCode
        statusMessage
        __typename
      }
      redirect {
        html
        iframeable
        uri
        __typename
      }
      __typename
    }
  }
`;


@Injectable({
  providedIn: 'root',
})
export class MillWithdrawalService {
  constructor(private apollo: Apollo) { }

  public fetchWithdrawalOptions(accessToken: string): Observable<WithdrawalOption[]> {
    return this.apollo
      .use('mill')
      .query<WithdrawalOptionResponse>({
        query: PAYMENT_OPTIONS_QUERY,
        variables: {
          type: "WITHDRAWAL"
        },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map((response) => {
          const paymentOptions = response.data?.paymentOptions;
          console.log('WithdrawalOptions response:', paymentOptions);
          return paymentOptions;
        })
      );
  }

  public createWithdrawal(accessToken: string, input: any): Observable<CreateWithdrawal> {
    return this.apollo
      .use('mill')
      .mutate<CreateWithdrawalResponse>({
        mutation: CREATE_PAYMENT_MUTATION,
        variables: {
          input: input
        },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      })
      .pipe(
        take(1),
        map((response) => {
          const createPayment = response.data?.createPayment;
          console.log('CreateWithdrawal response:', createPayment);
          return createPayment;
        })
      );
  }
}
