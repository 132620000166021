import { MillAuthStateService } from 'src/app/services/auth/the-mill/auth/auth-state/mill-auth-state.service';
import { Inject, Injectable } from "@angular/core";
import { Observable, of, catchError, forkJoin } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { BaseMillResponse, LoggedStatus } from "../auth/mill-auth.models";
import { HOST_NAME } from 'src/app/app-routing.module';
import { ActivateUserBonusInput, ActivateUserBonusResult, Bonus, BonusList, CancelUserBonusInput, CancelUserBonusResult, UserBonusConnection, UserBonusConnectionResponse, UserBonusResponse, UserCampaignBonusesResponse, UserDepositBonus, UserDepositBonuses, UserDepositBonusesResponse } from './mill-bonuses.models';

const USER_BONUS_CONNECTION_QUERY = gql`
query UserBonusConnection ($userId: ID!) {
  userBonusConnection (userId: $userId) {
      edges{
          node {
              bonusId
              createdAt
              activatedAt
              userBonusId
              status
              bonus {
                tags
              }
          }
      }
  }
}
`;

const ACTIVATE_USER_BONUS_MUTATION = gql`
mutation ActivateUserBonus ($input: ActivateUserBonusInput!) {
  activateUserBonus (input: $input) {
      userBonus{
          bonusId
          userBonusId
          errorMessage
      }
      }
  }
`;

const CANCEL_USER_BONUS_MUTATION = gql`
mutation cancelUserBonus($input: CancelUserBonusInput!) {
  cancelUserBonus(input: $input) {
    userBonus{
        activatedAt
        activationValidity
        bonus{
            deleted
        }
        bonusId
        errorMessage
        reason
        revokeReason
    }
  }
}
`;

const USER_DEPOSIT_BONUSES = gql`
query UserDepositBonuses ($userId: ID!) {
  userDepositBonuses (userId: $userId) {
          bonusId
          bonus {
              activation{
                  __typename
              }
          }
  }
}
`;

const USER_CAMPAIGN_BONUSES = gql`
query UserCampaignBonuses ($userId: ID!) {
  userCampaignBonuses (userId: $userId) {
      bonusId
      bonus {
          activation {
              __typename
          }
      }
      campaign {
          campaignId
      }
      eventType
  }
}
`;

const USER_BONUS_QUERY = gql`
query UserBonus($userBonusId: ID!) {
  userBonus(userBonusId: $userBonusId)  {
    wallet {
      userBonus {
        status
        wallet {
          realMoneyContribution

          lockedRealAmount
        }
      }
      realMoneyContribution
      userBonusId
      relatedWallet {
        relatedWallet {
          wageringProgress
        }
      }
    }
  }
}
`;

const CMS_BONUSES = gql`query MillBonusList ($bonusType: MillBonusType!) {
  millBonuses(where: {type: $bonusType}) {
    id
    millBonusId
    description {
      text
    }
    name
    amount
    type
  }
}
`

const CMS_BONUS = gql`query MillBonus ($millBonusId: ID!) {
  millBonus(where: {millBonusId: $millBonusId}) {
    id
    millBonusId
    description
    name
    amount
    type
  }
}
`

@Injectable({
  providedIn: 'root',
})
export class MillBonusesService {
  constructor(
    private apollo: Apollo,
    @Inject(HOST_NAME) public hostName,
    private millAuthStateService: MillAuthStateService
  ) { }

  public getUserBonus(userBonusId: string): Observable<UserBonusResponse> {
    return this.millAuthStateService.getIfUserLogged().pipe(
      take(1),
      switchMap((isLogged) => {

        const accessToken = isLogged.isLogged === LoggedStatus.logged ? isLogged.accessToken : null;

        const context = accessToken ? {
          headers: { Authorization: `Bearer ${accessToken}` },
        } : {};

        console.log('getUserBonus context:', context);

        return this.apollo
          .use('mill')
          .query<UserBonusResponse>({
            query: USER_BONUS_QUERY,
            variables: { userBonusId },
            context: context,
          }).pipe(
            take(1),
            map((response) => {
              const userBonus = response.data;
              console.log('getUserBonus response:', userBonus);
              return userBonus;
            }),
            catchError((error) => {
              console.log('Error:', error);
              return of(
                {
                  data: {
                    userBonus: null
                  }
                } as unknown as UserBonusResponse
              );
            })
          );
      })
    )
  }



  public getUserBonuses(): Observable<UserBonusConnection> {
    return this.millAuthStateService.getIfUserLogged().pipe(
      take(1),
      switchMap((isLogged) => {
        const accessToken = isLogged.isLogged === LoggedStatus.logged ? isLogged.accessToken : null;
        const userId = isLogged.isLogged === LoggedStatus.logged ? isLogged.userId : null;

        const context = accessToken
          ? { headers: { Authorization: `Bearer ${accessToken}` } }
          : {};

        console.log('getUserBonuses context:', context);

        return this.apollo
          .use('mill')
          .query<{ userBonusConnection: UserBonusConnection }>({
            query: USER_BONUS_CONNECTION_QUERY,
            variables: { userId },
            context: context,
          })
          .pipe(
            take(1),
            map((response) => response.data.userBonusConnection),
            catchError((error) => {
              console.error('Error in getUserBonuses:', error);
              return of({

                  edges: [],

              } as UserBonusConnection);
            })
          );
      })
    );
  }





  public getUserDepositBonuses(): Observable<UserDepositBonusesResponse> {
    return this.millAuthStateService.getIfUserLogged().pipe(
      take(1),
      switchMap((isLogged) => {

        const accessToken = isLogged.isLogged === LoggedStatus.logged ? isLogged.accessToken : null;
        const userId = isLogged.isLogged === LoggedStatus.logged ? isLogged.userId : null;

        const context = accessToken ? {
          headers: { Authorization: `Bearer ${accessToken}` },
        } : {};

        console.log('getUserDepositBonuses context:', context);

        return this.apollo
          .use('mill')
          .query<UserDepositBonusesResponse>({
            query: USER_DEPOSIT_BONUSES,
            variables: { userId },
            context: context,
          }).pipe(
            take(1),
            switchMap((response) => {
              return this.apollo
                .query<BonusList>({
                  query: CMS_BONUSES,
                  variables: {
                    bonusType: 'DEPOSIT'
                  }
                }).pipe(
                  take(1),
                  map((bonusList) => {
                    console.log('bonusList:', bonusList);
                    const userDepositBonuses = response.data;
                    userDepositBonuses.data.userDepositBonuses.forEach((userDepositBonus: UserDepositBonus) => {
                      const bonus = bonusList.data.millBonuses.find((bonus) => bonus.id === userDepositBonus.bonusId);
                      if (bonus) {
                        userDepositBonus.amount = bonus.amount;
                      }
                    });
                    console.log('getUserDepositBonuses response:', userDepositBonuses);
                    return userDepositBonuses;
                  })
                );
            }),
            catchError((error) => {
              console.log('Error:', error);
              return of(
                {
                  data: {
                    userDepositBonuses: []
                  }
                } as UserDepositBonusesResponse
              );
            })
          );
      })
    );
  }

  public getUserCampaignBonuses(): Observable<UserCampaignBonusesResponse> {
    return this.millAuthStateService.getIfUserLogged().pipe(
      take(1),
      switchMap((isLogged) => {

        const accessToken = isLogged.isLogged === LoggedStatus.logged ? isLogged.accessToken : null;
        const userId = isLogged.isLogged === LoggedStatus.logged ? isLogged.userId : null;

        const context = accessToken ? {
          headers: { Authorization: `Bearer ${accessToken}` },
        } : {};

        console.log('getUserCampaignBonuses context:', context);

        return this.apollo
          .use('mill')
          .query<UserCampaignBonusesResponse>({
            query: USER_CAMPAIGN_BONUSES,
            variables: { userId },
            context: context,
          }).pipe(
            take(1),
            map((response) => {
              const userCampaignBonuses = response.data;
              console.log('getUserCampaignBonuses response:', userCampaignBonuses);
              return userCampaignBonuses;
            }),
            catchError((error) => {
              console.log('Error:', error);
              return of(
                {
                  data: {
                    userCampaignBonuses: []
                  }
                } as UserCampaignBonusesResponse
              );
            })
          );
      })
    );
  }

  public activateUserBonus(payload: ActivateUserBonusInput): Observable<ActivateUserBonusResult> {
    return this.millAuthStateService.getIfUserLogged().pipe(
      take(1),
      switchMap((isLogged) => {
        const accessToken = isLogged.isLogged === LoggedStatus.logged ? isLogged.accessToken : null;

        const context = accessToken ? {
          headers: { Authorization: `Bearer ${accessToken}` },
        } : {};

        console.log('activateUserBonus context:', context);

        return this.apollo
          .use('mill')
          .mutate<ActivateUserBonusResult>({
            mutation: ACTIVATE_USER_BONUS_MUTATION,
            variables: { input: payload },
            context: context,
          }).pipe(
            take(1),
            map((response) => {
              const activateUserBonus = response.data;
              console.log('activateUserBonus response:', activateUserBonus);
              return activateUserBonus;
            }),
            catchError((error) => {
              console.log('Error:', error);
              return of(
                {
                  data: {
                    activateUserBonus: {
                      userBonus: {
                        bonusId: null,
                        userBonusId: null,
                        errorMessage: error.message
                      }
                    }
                  }
                } as ActivateUserBonusResult
              );
            })
          );
      })
    );
  }

  public cancelUserBonus(payload: CancelUserBonusInput): Observable<CancelUserBonusResult> {
    return this.millAuthStateService.getIfUserLogged().pipe(
      take(1),
      switchMap((isLogged) => {
        const accessToken = isLogged.isLogged === LoggedStatus.logged ? isLogged.accessToken : null;

        const context = accessToken ? {
          headers: { Authorization: `Bearer ${accessToken}` },
        } : {};

        console.log('cancelUserBonus context:', context);

        return this.apollo
          .use('mill')
          .mutate<CancelUserBonusResult>({
            mutation: CANCEL_USER_BONUS_MUTATION,
            variables: { input: payload },
            context: context,
          }).pipe(
            take(1),
            map((response) => {
              const cancelUserBonus = response.data;
              console.log('cancelUserBonus response:', cancelUserBonus);
              return cancelUserBonus;
            }),
            catchError((error) => {
              console.log('Error:', error);
              return of(
                {
                  data: {
                    cancelUserBonus: {
                      userBonus: {
                        activatedAt: null,
                        activationValidity: null,
                        bonus: {
                          deleted: false
                        },
                        bonusId: null,
                        errorMessage: error.message,
                        reason: null,
                        revokeReason: null
                      }
                    }
                  }
                } as CancelUserBonusResult
              );
            })
          );
      })
    );
  }
}
