import { MillAuthStateService } from '../../../services/auth/the-mill/auth/auth-state/mill-auth-state.service';
import { Component, ElementRef, Inject, NgZone, PLATFORM_ID, ViewChild } from '@angular/core';
import {
  depositTrx,
  gamesCategoryTrx,
  inboxTrx,
  liveCasinoTrx,
  paymentTrx,
  promotionsTrx,
  searchGameTrx,
  signUpTrx
} from 'src/app/router-translation.labels';
import { MIX_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { MatDialog } from '@angular/material/dialog';
import { GameCategoryDialogComponent } from '../../front-page/game-category-dialog/game-category-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { switchMap, take, takeUntil } from 'rxjs/operators';
//import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import * as PixiSpine from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { isPlatformServer } from '@angular/common';
import { Observable, Subscription, of, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

const ANIMATION_CANVAS_SIZE_HEIGHT = 27;
const ANIMATION_CANVAS_SIZE_WIDTH = 27;
const ANIMATION_SIZE_WIDTH = 140;
const ANIMATION_NAME = 'animation';

const PATH_TO_ANIMATION_JSON = 'assets/animations/registration/registration_icon.json';

@Component({
  selector: 'app-bottom-toolbar',
  templateUrl: './bottom-toolbar.component.html',
  styleUrls: ['./bottom-toolbar.component.scss']
})
export class BottomToolbarComponent extends BasePageComponent {

  app: PIXI.Application;
  canvasHeight: number;
  isServer: boolean;
  animationReady: boolean = false;

  @ViewChild('pixiAnimation') pixiAnimation: ElementRef;

  private animationSize = {
    width: ANIMATION_CANVAS_SIZE_WIDTH,
    height: ANIMATION_CANVAS_SIZE_HEIGHT,
    scale: ANIMATION_CANVAS_SIZE_WIDTH / ANIMATION_SIZE_WIDTH,
  };

  paymentUrl: string = this.translationConfig.getTranslation(paymentTrx) + '/' + this.translationConfig.getTranslation(depositTrx);
  searchUrl: string = this.translationConfig.getTranslation(searchGameTrx);
  singnUrl: string = this.translationConfig.getTranslation(signUpTrx);
  inboxUrl: string = this.translationConfig.getTranslation(inboxTrx);
  liveCasinoUrl: string = this.translationConfig.getTranslation(liveCasinoTrx);
  promotionsUrl: string = promotionsTrx;
  gameCategoryUrl: string = this.translationConfig.getTranslation(gamesCategoryTrx);
  isLogged: boolean = false;
  isHidden: boolean = false;
  unreadMessages: number = 0;
  timerSubscription$: Subscription;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    protected translationConfig: TranslationConfig,
    //private loginStatusService: LoginStatusService,
    private millAuthStateService: MillAuthStateService,
    protected licenceService: LicenceService,
    protected minimizeService: MinimizeSiteService,
    @Inject(PLATFORM_ID) private platformId,
    private ngZone: NgZone,
  ) { super() }


  ngOnInit(): void {
    this.isServer = isPlatformServer(this.platformId);
    this.millAuthStateService.getIfUserLogged().pipe(take(1)).subscribe(() => {
      this.isLogged = true;
    })
    this.minimizeService.isToolbarNotMinimized().pipe(takeUntil(this.unsubscribe)).subscribe((isNotMinimized) => {
      this.isHidden = !isNotMinimized;
    })
  }

  ngAfterViewInit() {
    if (!this.isServer) {
      this.ngZone.runOutsideAngular(() => {
        timer(0, 100).pipe(
          switchMap(() => this.initializePixi()),
          takeUntil(this.unsubscribe)
        ).subscribe();
      });
    }
  }

  initializePixi(): Observable<void> {
    if (this.pixiAnimation && this.pixiAnimation.nativeElement) {
      this.app = new PIXI.Application({
        preserveDrawingBuffer: true,
        backgroundAlpha: 0,
        width: this.animationSize.width,
        height: this.animationSize.height,
      });

      PIXI.Assets.load(PATH_TO_ANIMATION_JSON).then(this.onAssetsLoaded);

      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
    return of(null);
  }

  onAssetsLoaded = (registrationAsset) => {
    const registrationSpine = new PixiSpine.Spine(registrationAsset.spineData);

    registrationSpine.state.data.skeletonData.bones;
    registrationSpine.scale.x = Math.round(this.animationSize.scale * 100) / 100;
    registrationSpine.scale.y = Math.round(this.animationSize.scale * 100) / 100;
    registrationSpine.x = this.animationSize.width / 2;
    registrationSpine.y = this.animationSize.height / 2;

    registrationSpine.state.setAnimation(0, ANIMATION_NAME, true);

    if (this.app && this.app.stage) {
      this.app.stage.addChild(registrationSpine);
    }

    registrationSpine.on('pointerdown', async () => {
      console.log('pointerdown');
      this.router.navigate(["", this.translationConfig.getTranslation(signUpTrx)]);
    });

    registrationSpine.interactive = true;
    this.app.stage.interactive = true;

    if (this.pixiAnimation && this.pixiAnimation.nativeElement && this.app && this.app.view) {
      this.pixiAnimation.nativeElement.appendChild(this.app.view);
    }
    this.animationReady = true;
  };

  openCategoryDialog(msg?: string, title?: string): void {
    const dialogRef = this.dialog.open(GameCategoryDialogComponent, {
      ...MIX_DIALOG_CONFIG,
      backdropClass: "fullPrimaryBackdropBackground",
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.router.navigate([result], { relativeTo: this.route });
    });
  }

  catagoryRedirect() {
    this.router.navigate([this.gameCategoryUrl]);
  }

}
