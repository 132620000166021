import { Apollo } from 'apollo-angular';
import { Component, Inject, PLATFORM_ID, AfterViewInit, LOCALE_ID, Injector, Type, NgModuleRef, createNgModuleRef, ViewContainerRef, ViewChild, ElementRef, HostListener, createNgModule } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { ExapnsionPanels, ExpansionPanelItem, GameCategory } from 'src/app/apollo/models/base-models';
import { FRONT_PAGE_EXPANSION_PANEL } from 'src/app/apollo/front-page-games/front-page-games';
import { DomSanitizer } from '@angular/platform-browser';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { OpenDesktopDrawerService } from 'src/app/services/utils/open-desktop-drawer.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import { COUNTRY_ID, LICENCE } from 'src/app/app-routing.module';
import { FilterGamesService } from 'src/app/services/utils/filter-games.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { missionsTrx, tournamentsTrx } from 'src/app/router-translation.labels';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { MainJackpotsComponent } from '../jackpot/main-jackpots.component';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { RecommendationService } from '../../services/recommendation/recommendation.service';
import { LoginStatusService } from '../../services/auth/login/login-status.service';
import { TournamentsCarouselComponent } from './tournaments-carousel/tournaments-carousel.component';
import { NotificationService } from 'src/app/services/notofications/notification.service';
import { AdformTrackingPage, AdformTrackingService } from 'src/app/services/tracking/adform-tracking.service';
import { BasePageComponentWithDialogs } from '../base-page/base-page.component';
import { MatDialog } from '@angular/material/dialog';
import { MissionsCarouselComponent } from './missions-carousel/missions-carousel.component';
import { combineLatest, timer } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { BonusListService } from 'src/app/services/payment/bonus/bonus-list.service';
import { loadChatScript } from 'src/app/utils/load-chat';


@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent extends BasePageComponentWithDialogs implements AfterViewInit {

  categories: GameCategory[];
  operators: GameCategory[];
  expansionPanel: ExpansionPanelItem[];
  public tournamentsAllTrx = this.translationConfig.getTranslation(tournamentsTrx);
  @ViewChild('jackpots', { static: false, read: ViewContainerRef })
  public viewRef: ViewContainerRef;
  protected isShowJackpotModulePlaceholder = true;
  public missionsAllTrx = this.translationConfig.getTranslation(missionsTrx);

  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private apollo: Apollo,
    private toggleBackService: ToggleMenuBackService,
    private loadingService: LoaderService,
    public detectDeviceService: DetectDeviceService,
    public openDrawerService: OpenDesktopDrawerService,
    public minimizeSiteService: MinimizeSiteService,
    private frontPageGamesService: FrontPageGamesService,
    private cloneService: ClonerService,
    private FilterGamesService: FilterGamesService,
    protected translationConfig: TranslationConfig,
    protected licenceService: LicenceService,
    private recommendationService: RecommendationService,
    private loginStatusService: LoginStatusService,
    private notificationService: NotificationService,
    private adformService: AdformTrackingService,
    private prevUrlService: DialogClosePreviousUrl,
    private bonusListService: BonusListService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(COUNTRY_ID) public countryId: string,
    @Inject(LICENCE) public licence: string,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(WINDOW) protected windowRef: Window,

    private _injector: Injector,

    dialog: MatDialog,
    private detectDesktopService: DetectDeviceService,
  ) { super(dialog, detectDesktopService) }

  @ViewChild('tournaments', { static: false, read: ViewContainerRef })
  public tournamentsRef: ViewContainerRef;

  @ViewChild('missions', { static: false, read: ViewContainerRef })
  public missionsRef: ViewContainerRef;

  protected isShowTournamentsModulePlaceholder = true;
  protected isShowMissionsModulePlaceholder = true;

  ngAfterViewInit(): void {
    timer(300).pipe(take(1)).subscribe(() => {
      console.log("scrolling to previous position", this.prevUrlService.getScrollPosition())
      this.windowRef.scrollTo(0, this.prevUrlService.getScrollPosition());
    })
  }

  private _openLazyLoadedComponent<T>(
    importedFile: T,
    componentToOpen: Type<MainJackpotsComponent>
  ): void {
    this.isShowJackpotModulePlaceholder = false;
    const module: Type<T> = (<any>importedFile)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModuleRef(module, this._injector);
    this.viewRef.createComponent(componentToOpen, { ngModuleRef: moduleRef });
  }

  private _openLazyLoadedTournamentsComponent<T>(
    importedFile: T,
    componentToOpen: Type<TournamentsCarouselComponent>
  ): void {
    this.isShowTournamentsModulePlaceholder = false;
    const module: Type<T> = (importedFile as any)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector);
    this.tournamentsRef.createComponent(componentToOpen, { ngModuleRef: moduleRef });
  }
  private _openLazyLoadedComponentMissions<T>(
    importedFile: T,
    componentToOpenMissions: Type<MissionsCarouselComponent>
  ): void {
    this.isShowMissionsModulePlaceholder = false;
    const module: Type<T> = (importedFile as any)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector);
    this.missionsRef.createComponent(componentToOpenMissions, { ngModuleRef: moduleRef });
  }

  ngOnInit(): void {
    // this.loadingService.show();
    this.toggleBackService.hideBack();

    this.frontPageGamesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      let categories = this.cloneService.deepClone<GameCategory[]>(response.pagesCategories[0].categories);
      console.log('categories1', categories);

      this.setSEOTags(response.pagesCategories[0].seo?.metaTitle, response.pagesCategories[0].seo?.metaDescription);
      this.categories = this.FilterGamesService.filterCategories(categories);
      console.log('categories2', categories);
      this.operators = response.operators[0]?.categories;
      this.loadingService.hide();
    });
    if (!isPlatformServer(this.platformId)) {
      import('../../modules/jackpot/jackpot.module').then((importedFile) => {
        const componentToOpen =
          importedFile.JackpotModule.components.dynamicComponent;
        this._openLazyLoadedComponent(importedFile, componentToOpen);
      });

      import('../../components/tournaments/tournaments-carousel.dynamic.module').then((importedFile) => {
        const componentToOpen =
          importedFile.TournamentsCarouselDynamicModule.components.dynamicComponent;
        this._openLazyLoadedTournamentsComponent(importedFile, componentToOpen);
      });

      import('../../components/missions/missions-carousel.dynamic.module').then((importedFile) => {
        const componentToOpenMissions =
          importedFile.MissionsCarouselDynamicModule.components.dynamicComponent;
        this._openLazyLoadedComponentMissions(importedFile, componentToOpenMissions);
      });
    }

    this.adformService.setTrackingPage(AdformTrackingPage.HOMEPAGE);

    // this.notificationService.sendNotification("misssion-started")
    this.apollo
      .watchQuery<ExapnsionPanels>({
        query: FRONT_PAGE_EXPANSION_PANEL,
        variables: {
          locale: this.locale,
          licenseName: this.licence
        }
      })
      .valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
        const expansionPanelResponse = response.data.expansionPanels.length > 1 ? response.data.expansionPanels.filter(item => item.licence !== 'all')[0] : response.data.expansionPanels[0];
        const resp: ExpansionPanelItem[] = this.cloneService.deepClone(expansionPanelResponse.item);

        this.expansionPanel = resp.map(item => {
          if (!!item && !!item.content) {
            item.content.text = item.content.html.replace(/<[^>]+>/g, '');
            item.content.safehtml = this.sanitizer.bypassSecurityTrustHtml(item.content.html);
            return item;
          }
        });
      });

    this.loginStatusService.getIfUserLogged().pipe(takeUntil(this.unsubscribe),
      switchMap(() => {
        return this.recommendationService.sendHomePageOpenedEvent().pipe(take(1))
      })).subscribe((resp) => {

      });

    if (this.licence === 'bison_india') {
      if (isPlatformBrowser(this.platformId)) {
        this.loginStatusService.getIfUserLogged().pipe(takeUntil(this.unsubscribe)).subscribe((status) => {
          loadChatScript(this.locale, status.username, status.fullInfo.first_name);
          console.log("chat enabled")
        });
      }
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const componentPosition = this.elementRef?.nativeElement?.querySelector('#expansionPanel')?.offsetTop;
    if (!componentPosition) return;
    const scrollPosition = window.pageYOffset + window.innerHeight;
    if (scrollPosition >= componentPosition) {
      if (this.expansionPanel) {
        this.expansionPanel.forEach(element => {
          element.display = true;
        });
      }
    }
  }

}
