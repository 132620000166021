import { MillAuthStateService } from '../../../services/auth/the-mill/auth/auth-state/mill-auth-state.service';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { UserInfoService } from 'src/app/services/auth/check-session/user-info.service';
import { LoggedStatus, UserInfo } from 'src/app/services/auth/login/login.models';
import { take, takeUntil } from 'rxjs/operators';
import { BasePageComponent, BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { DialogConfig, FULL_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { MatDialog } from '@angular/material/dialog';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { isPlatformServer } from '@angular/common';
//import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-auth',
  templateUrl: './main-auth.component.html',
  styleUrls: ['./main-auth.component.scss']
})
export class MainAuthComponent extends BasePageComponentWithDialogs {

  userInfo: UserInfo;
  dialogSize: DialogConfig = FULL_DIALOG_CONFIG;
  isLoading: boolean = true;
  isLogged: boolean = false;


  constructor(
    private userInfoLoginService: UserInfoService,
    //private loginStatusService: LoginStatusService,
    private millAuthStateService: MillAuthStateService,
    public detectDeviceService: DetectDeviceService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId,
    private dialog: MatDialog,
    protected detectDesktopService: DetectDeviceService,
    public errorDialog: MatDialog,
  ) { super(errorDialog, detectDesktopService) }

  ngOnInit(): void {
    // if (this.detectDesktopService.isDesktop()){
    //   this.dialogSize = DESKTOP_REGULAR
    // }
    if (!isPlatformServer(this.platformId)) {

      this.millAuthStateService.getLoginPartialStatus().pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
        this.isLoading = false;
        this.isLogged = resp;
        console.log('main-auth isLogged', this.isLogged);

      })

      this.userInfoLoginService.requestCheckUserInfo().pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
        this.isLoading = false;
        if (this.userInfo && this.userInfo.isLogged === LoggedStatus.logged && resp.isLogged === LoggedStatus.notLogged) {
          // this.router.navigate(['/'], { relativeTo: this.route });
          window.history.replaceState({}, '', '/');
          location.reload();
        }

        if (resp.isLogged === LoggedStatus.changePassword) {
          const dialogRef = this.dialog.open(NewPasswordComponent, {
            ...this.dialogSize,
            disableClose: true,
            data: {}
          });

          dialogRef.afterClosed().subscribe(result => {
            this.openSuccessDialog(null, null, () => {
              this.router.navigate([''],)
            });
            this.userInfoLoginService.requestCheckUserInfo().pipe(take(1), takeUntil(this.unsubscribe)).subscribe((resp) => { });
          });


        }
        this.userInfo = resp;
      })



    }



  }


}
