import { MillAuthStateService } from '../the-mill/auth/auth-state/mill-auth-state.service';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LoggedStatus, LogoutResponse, UserInfo } from '../login/login.models';
import { LOGOUT_URL } from '../../http/base-http.service';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class LogoutService{


  constructor(http: HttpClient,
    cookie: CookieService,
    private millAuthStateService: MillAuthStateService,
    @Inject(LOCALE_ID)
    public locale: string,
    @Inject(HOST_NAME)
    public hostName) {
  }

  public requestLogoutUser(): Observable<UserInfo> {
    this.millAuthStateService.logoutUser();
    this.millAuthStateService.updateAuthState(LoggedStatus.notLogged);
    return this.millAuthStateService.getLoginStatus();
  }
}
