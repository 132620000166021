import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { PENDING_WITHDRAWAL } from '../../http/base-http.service';
import { Observable } from 'rxjs';
import { PendingWithdrawalList } from './withdrawal.models';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class PendingWithdrawalService {

  constructor(http: HttpClient, cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {

  }

  public getList(): Observable<PendingWithdrawalList>{
    //return super.get<PendingWithdrawalList>(PENDING_WITHDRAWAL);
    return null as any;
  }
}
