<div class="game-card-wrapper swiper-grid-cards" *ngIf="!isServer && !isDesktop else server">
  <swiper #swiper [config]="config" fxFlex="auto" class="dynamic-category-swiper">
    <ng-template *ngFor="let item of games" swiperSlide>
      <div class="game-card">
        <app-game-card [isOutsideCategory]="isOutsideCategory" (clickEvent)="openDialog($event)"
                       [data]="item"></app-game-card>
      </div>
    </ng-template>

    <ng-template *ngFor="let item of emptyElements" swiperSlide>
      <div class="game-card-placeholder" [ngClass]="isLoading ? 'skeleton' : ''">
        <img src="assets/images/bison-thumb-placeholder.svg" />
      </div>
    </ng-template>
  </swiper>
</div>

<ng-template #server>
  <div class="game-card-wrapper">
    <ng-container *ngFor="let item of games">
      <div class="game-card">
        <app-game-card [isOutsideCategory]="isOutsideCategory" (clickEvent)="openDialog($event)"
                       [data]="item"></app-game-card>
      </div>
    </ng-container>

    <ng-container *ngFor="let item of emptyElements">
      <div class="game-card-placeholder" [ngClass]="isLoading? 'skeleton' : ''">
        <img src="assets/images/bison-thumb-placeholder.svg"/>
      </div>
    </ng-container>
  </div>
</ng-template>
