import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { WebSocketLink } from 'apollo-link-ws';
import { CreateDeposit, CreateDepositInput, CreateDepositResponse, DepositMethod, DepositOption, DepositOptionResponse, MillPaymentMethodList, PaymentMethod } from './mill-deposit.models';

const PAYMENT_OPTIONS_QUERY = gql`
  query PaymentOptions($type: PaymentType!) {
    paymentOptions(type: $type) {
      accounts {
        id
        label
        __typename
      }
      currencyCode
      defaultAmount
      fixedFee
      gateway
      max
      method
      min
      percentFee
      provider
      quickOptions
      type
      __typename
    }
  }
`;


const CREATE_PAYMENT_QUERY = gql`
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      payment {
        errorMessage
        id
        status
        statusCode
        statusMessage
        __typename
      }
      redirect {
        html
        iframeable
        uri
        __typename
      }
      __typename
    }
  }
`;

const CMS_DEPOSIT_INFO = gql`query MillPaymentMethodList ($paymentType: MillPaymentType!) {
  millPaymentMethods(where: {type: $paymentType, enabled: true}) {
    id
    displayName
    description {
      text
    }
    enabled
    fieldsSet
    gateway
    method
    provider
    thumbnail {
      url
    }
    thumbnailMobile {
      url
    }
    thumbnailNew {
      url
    }
    type
  }
}
`


@Injectable({
  providedIn: 'root',
})
export class MillDepositService {
  constructor(private apollo: Apollo) { }

  public fetchDepositOptions(accessToken: string): Observable<DepositMethod[]> {

    const context = accessToken ? {
      headers: { Authorization: `Bearer ${accessToken}` },
    } : {};

    console.log('fetchDepositOptions context:', context);


    return this.apollo
      .use('mill')
      .query<DepositOptionResponse>({
        query: PAYMENT_OPTIONS_QUERY,
        variables: {
          type: "DEPOSIT"
        },
        context: context
      })
      .pipe(
        take(1),
        switchMap((optionsResponse) => {

          return this.apollo.query<MillPaymentMethodList>({
            query: CMS_DEPOSIT_INFO,
            variables: {
              paymentType: "deposit"
            },
          }).pipe(
            take(1),
            map((response) => {
              const paymentMethods = response.data?.millPaymentMethods;
              // combine to list of PaymentMethod with optionsResponse by gateway, method and provider fields, main list is paymentMethods

              return paymentMethods.map((method) => {
                const option = optionsResponse.data?.paymentOptions.find((option) => option.gateway === method.gateway && option.method === method.method && option.provider === method.provider);
                return {
                  ...method,
                  ...option
                } as DepositMethod;
              });
            })
          );

          // const paymentOptions = response.data?.paymentOptions;
          // console.log('PaymentOptions response:', paymentOptions);
          // return paymentOptions;
        })
      );
  }

  public createDeposit(accessToken: string, input: CreateDepositInput): Observable<CreateDeposit> {
    return this.apollo
      .use('mill')
      .mutate<CreateDepositResponse>({
        mutation: CREATE_PAYMENT_QUERY,
        variables: { input },
        context: {
          headers: { Authorization: `Bearer ${accessToken}` },

        },
      })
      .pipe(
        take(1),
        map((response) => {
          const createPayment = response.data?.createPayment;
          console.log('CreatePayment response:', createPayment);
          return createPayment;
        })
      );
  }
}
