import { MillAuthStateService } from '../../services/auth/the-mill/auth/auth-state/mill-auth-state.service';
import { Apollo } from 'apollo-angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, NgZone, PLATFORM_ID } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';
import { filter, takeUntil } from 'rxjs/operators';

import { FRONT_PAGE_TOP_BANNER } from 'src/app/apollo/front-page-games/front-page-games';
import { Banner, BannerItem, BannerResponse } from 'src/app/apollo/models/base-models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
//import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import SwiperCore, { Autoplay, SwiperOptions } from 'swiper';
import { filterBannerLicence } from 'src/app/utils/licence_filter';
import { LICENCE } from 'src/app/app-routing.module';

SwiperCore.use([Autoplay]);
interface TranslatedBannerItem extends BannerItem {

}

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBannerComponent extends BasePageComponent {

  static IDENTIFIER: string = "top_banner";
  showNavigationArrows = false;
  topBanner: BannerItem[];
  isAuthenticated: boolean;
  isServer: boolean;
  player: any;


  config: SwiperOptions = {
    direction: 'horizontal',
    scrollbar: false,
    watchSlidesProgress: true,
    navigation: false,
    keyboard: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    centeredSlides: true,
    loop: true,
    roundLengths: true,
    spaceBetween: 0,
    on: {
      slideChangeTransitionEnd: this.onIndexChange
    }
  };

  constructor(
    public detectDeviceService: DetectDeviceService,
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private translationConfig: TranslationConfig,
    //private loginStatusService: LoginStatusService,
    private millAuthStateService : MillAuthStateService,
    private cloneService: ClonerService,
    private chaengeDetectionRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
  ) { super() }


  swiperReady(): void {
    // this.ngZone?.runOutsideAngular(() => {
    //   new spine.SpinePlayer("spine-placeholder-0", {
    //     jsonUrl: "assets/playbison/animation-banner/welcome/welcome_banner_400x194.json",
    //     atlasUrl: "assets/playbison/animation-banner/welcome/welcome_banner_400x194.atlas",
    //     showControls: false,
    //     success: function (player) {
    //       document.getElementById('static-banner-placeholder-0').remove();
    //       document.getElementById('spine-placeholder-0').classList.remove('hidden');
    //       player.animationState.addAnimation(0, "animation", true, 0);
    //     },
    //     viewport: {
    //       x: -window.innerWidth / 2,
    //       y: -90,
    //       width: window.innerWidth,
    //       height: 180,
    //       padLeft: "0%",
    //       padRight: "0%",
    //       padTop: "0%",
    //       padBottom: "0%"
    //     }
    //   });
    // });
  }

  displayBanner() {
    this.apollo
      .watchQuery<BannerResponse>({
        query: FRONT_PAGE_TOP_BANNER,
        variables: {
          locale: this.locale,
          language: this.locale,
          licenseName: this.licence,
        }
      })
      .valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
        this.topBanner = this.cloneService.deepClone(filterBannerLicence(response.data.banners, this.licence)?.bannerItems);
        if (this.isAuthenticated) {
          this.topBanner = this.topBanner?.filter(item => !item.hideForLogged);
        }
        else {
          this.topBanner = this.topBanner?.filter(item => !item.hideForNotLogged);
        }
        if (!isPlatformServer(this.platformId)) {
          this.isServer = false;
          this.topBanner.forEach(element => {

            element.buttonLink = this.translationConfig.getTranslation(element.buttonLink);
          });
        }
        else {
          this.isServer = true;
        }
        this.chaengeDetectionRef.detectChanges();
        this.swiperReady();
      });
  }

  ngOnInit(): void {
    this.millAuthStateService.getLoginPartialStatus().pipe(takeUntil(this.unsubscribe),
    ).subscribe(resp => {
      this.isAuthenticated = resp;
      if (this.isAuthenticated) {
        // this.displayBanner();
        this.topBanner = this.topBanner?.filter(item => !item.hideForLogged);
        this.chaengeDetectionRef.detectChanges();
      }
    });
    this.displayBanner();
  }

  onIndexChange($event) {
    var welcomeVideo = <HTMLVideoElement>document.getElementById(`welcome-${$event.realIndex}`);
    if (welcomeVideo) {
      welcomeVideo.play();
    }

    // const originalSlideCount = $event.slides.length - 2;
    // if ($event.activeIndex === originalSlideCount + 1) {
    //   $event.slideTo(1, 0, false);
    // }
    // if ($event.realIndex != 0) {
    //   this.player.pause();
    // }
    // else if (!!this.player) {
    //   this.player.play();
    // }
  }

  openLink(item: BannerItem) {
    this.router.navigate((this.isAuthenticated ? item.loggedButtonLink : item.buttonLink).split("/").map(item => this.translationConfig.getTranslation(item)), { relativeTo: this.route })
  }

  trackByBannerItems(index: number, item: BannerItem): string {
    return item.name;
  }
  getVideoLink(url:string) {
    let now = new Date();
    let time = now.getTime();
    return url+'?t='+time;
  }

}
