<ng-container *ngIf="!isDesktop else desktop">
  <ng-container *ngIf="!!gamificationIndex && gamificationIndex>-0">
    <div *ngIf="isLogin" class="profile-tool mobile" fxLayout="row" fxLayoutAlign="center center">
      <div class="profile-container mobile" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <div class="content mobile-top" fxLayoutAlign="space-between start" fxLayout="row">
          <div class="profile-name" fxLayoutAlign="center start" fxLayout="column">
            <div class="thumbnail mobile-top level-{{gamificationIndex}}" (click)="redirectToProfile()">
              <ng-container>
                <!-- <img src="assets/playbison/levels/icon-playbison-level-{{gamificationIndex}}.gif" alt="icon-gamification" /> -->
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #desktop>
  <div *ngIf="isLogin" class="profile-tool desktop" fxLayout="row" fxLayoutAlign="center center">
    <div class="profile-container" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <app-profile-info size="desktop-top"></app-profile-info>
      <hr class="vertical-line" fxFlex="none" fxOrder="3" fxLayoutAlign="start" />
    </div>
  </div>
</ng-template>
