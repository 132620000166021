import { Apollo, gql } from 'apollo-angular';
import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest, of, timer } from 'rxjs';
import { delay, distinctUntilChanged, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus, UserState } from 'src/app/services/auth/login/login.models';
import { BasePageComponent } from '../base-page/base-page.component';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { SwiperOptions } from 'swiper';
import { ChargebackService } from 'src/app/services/profile/chargeback.service';
import { BonusLotteryService } from 'src/app/services/profile/bonuslottery.service';

const TOP_BANNER_HEIGHT = 120;


interface TeserCountDown {
  minutes: string,
  seconds: string
}
interface Teaser {
  name: string
  msg: string
  iconName: string
  url: string
  page?: {
    name
    category
  }
}

const query = gql`query TeaserQuery($name: String!,$locale: Locale!){
  teaser(locales: [$locale, en],where: {name: $name}) {
      name
      url
      msg
      iconName
      page{
        name
        category
      }
    }
}
`

export const ACTIVE_PROMOTION_DISPLAY = "activePromotionDisplay";
export const FIRST_DEPOSIT_BONUS_WITH_DATE = "bonusFirstDepositWithDate";
export const FIRST_DEPOSIT_BONUS_WITH_DATE_NO_COUNTDOWN = "bonusFirstDepositNoCountDown";
export const CLAIM_CASHBACK = "claimCashback";
export const DEPOSIT_TEASER = "depositTeaserDisplay";
export const CHRISTMAS_CALENDAR = "christmascalendar";
@Component({
  selector: 'app-teaser-top-mobile',
  templateUrl: './teaser-top-mobile.component.html',
  styleUrls: ['./teaser-top-mobile.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserTopMobileComponent extends BasePageComponent implements AfterViewInit {

  constructor(
    protected loginStatusService: LoginStatusService,
    @Inject(LOCALE_ID) public locale: string,
    protected apollo: Apollo,
    protected router: Router,
    protected translationConfig: TranslationConfig,
    protected chargebackService: ChargebackService,
    protected bonusLotteryService: BonusLotteryService
  ) {
    super()
  }

  public isShown: boolean = true;
  public teaser: Teaser;
  isClosedByUser: boolean = false;
  private isLogged: boolean = false;
  private userState: UserState;
  isIconFromAssets: boolean = true;
  teaserName: string = null;
  validTill: Date = null;
  public countDown: TeserCountDown;
  isClosable: boolean = false;
  isFirstDepositBonusLottery: boolean = false;
  isFirstDepositBonusNoCounter: boolean = false;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    }
  };

  multiTeaser: any;
  @ViewChild('imageteaser') imageTeser: ElementRef;



  getYPosition(e: any): number {
    return e.target.scrollingElement.scrollTop;
  }

  getData() {
    this.loginStatusService.getLoginStatus().pipe(
      filter(status => status.isLogged !== LoggedStatus.voidState),
      distinctUntilChanged(),
      delay(0),
      switchMap(status => {
        this.teaser = null
        if (status.isLogged === LoggedStatus.logged) {
          this.isLogged = true;

          return combineLatest(
            {
              userState: this.loginStatusService.getUserState(),
              cashBackCLaim: this.chargebackService.isHasCashbackToClaim(),
              bonusLottery: this.bonusLotteryService.getBonusLottery()
            }
          )
            .pipe(
              filter((resp) => !!resp.userState.dbUser),
              switchMap((resp) => {
                this.userState = resp.userState;
                this.teaserName = this.selectTeaserForUser(resp.userState, !!resp.cashBackCLaim, resp.bonusLottery.isActive);
                if (this.teaserName === FIRST_DEPOSIT_BONUS_WITH_DATE) {
                  this.isFirstDepositBonusLottery = true;
                }
                if (this.teaserName === FIRST_DEPOSIT_BONUS_WITH_DATE_NO_COUNTDOWN) {
                  this.isFirstDepositBonusNoCounter = true;
                }
                if (this.teaserName) {
                  return this.apollo.watchQuery<{ teaser: Teaser }>({
                    query: query,
                    variables: {
                      name: this.teaserName,
                      locale: this.locale
                    }
                  }).valueChanges
                } else return of(null)
              })
            )
        }
        else {
          this.isLogged = false;
          return of(null)
        }
      }),
      filter(item => !!item),
      takeUntil(this.unsubscribe),
    ).subscribe((teaserResp) => {
      this.teaser = teaserResp.data.teaser;
      // this.isIconFromAssets = this.teaser?.iconName?.includes('.')
    });
  }

  ngOnInit(): void {
    // this.chargebackService.isHasCashbackToClaim().subscribe(resp => {
    //   console.log("store claim init", resp)
    // })
  }

  ngAfterViewInit() {
    this.getData();
  }

  selectTeaserForUser(state: UserState, cashBackCLaim: boolean, isActiveLottery: boolean): string {
    this.isIconFromAssets = false;
    console.log(state.dbUser.firstDepositBonus && !state.dbUser.firstDepositBonus?.isUsed)
    if (cashBackCLaim) {
      return CLAIM_CASHBACK;
    }
    else if (isActiveLottery) {
      return ACTIVE_PROMOTION_DISPLAY
    }
    else if (state.dbUser.firstDepositBonus && !state.dbUser.firstDepositBonus?.isUsed && state.dbUser?.firstDepositBonus?.validTill.toDate() > new Date()) {
      this.validTill = state.dbUser.firstDepositBonus?.validTill.toDate();

      if (!this.countDown) {
        this.countDown = {
          minutes: '0',
          seconds: '0'
        }


        const source = timer(0, 1000).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          const diff = Math.max(0, Math.floor(Math.floor((this.validTill.getTime() - new Date().getTime()) / 1000)));
          let seconds = diff;
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
          let days = Math.floor(hours / 24);

          hours = hours - (days * 24);
          minutes = minutes - (days * 24 * 60) - (hours * 60);
          seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

          this.countDown.minutes = minutes.toString();
          this.countDown.seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

          if (minutes === 0 && seconds === 0) {
            source.unsubscribe();
            this.countDown = null;
            this.isShown = false;
          }

        })
      }

      else {
        this.countDown = null;
      }
      this.isClosable = false;
      return FIRST_DEPOSIT_BONUS_WITH_DATE
    }
    else if (state.dbUser.firstDepositBonus && !state.dbUser.firstDepositBonus?.isUsed) {
      return FIRST_DEPOSIT_BONUS_WITH_DATE_NO_COUNTDOWN
    }
    else if (state.dbUser.activePromotionDisplay && !state.dbUser.activePromotionDisplay?.closedByUser) {
      this.isClosable = false;
      return ACTIVE_PROMOTION_DISPLAY
    }
    else if (!state.dbUser.depositTeaserDisplay?.closedByUser) {
      this.isClosable = true;
      return DEPOSIT_TEASER
    }
    // else {
    //   this.isIconFromAssets = true;

    //   return CHRISTMAS_CALENDAR;
    // }

  }

  openUrl() {
    const teaserURL = this.teaser.url.split('/').map(item => this.translationConfig.getTranslation(item));

    if (!!this.teaserName) {
      if (this.teaser.name !== ACTIVE_PROMOTION_DISPLAY) {
        this.loginStatusService.getUserState().pipe(
          take(1),
        ).subscribe((state) => {
          state.dbUser[this.teaser.name] = { closedByUser: true };
          this.loginStatusService.updateUserState("dbUser", state.dbUser, true);
        })
      }
    } else {
      // this.gtag?.event(`teser clicked: not logged`);
    }
    this.router.navigate(["", ...teaserURL])
  }


  close() {
    this.isClosedByUser = true;
    this.loginStatusService.getUserState().pipe(
      filter(state => !!state),
      take(1),
    ).subscribe((state) => {
      if (state && !!this.teaserName) {
        state.dbUser[this.teaser.name] = { closedByUser: true };
        this.loginStatusService.updateUserState("dbUser", state.dbUser, true)
      }
    }
    )
  }

}
