<div class="login-form-wrapper desktop-panel" fxLayout="row" fxLayoutAlign="center center">
  <div id="login-form" class="form-custom-design">
    <div fxLayout="row" fxLayoutAlign="center center">
      <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)">
        <mat-form-field appearance="outline">
          <mat-label i18n>E-mail</mat-label>
          <input matInput type="text" formControlName="login" autocomplete="username" cdkFocusInitial>
          <img class="img-suffix" src="{{licenceService.getKey('icon-email')}}" matSuffix>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label i18n>password</mat-label>
          <input matInput autocomplete="current-password" [type]="hidePassword ? 'password' : 'text'" formControlName="password">
          <img class="img-suffix" src="assets/playbison/{{hidePassword ? 'eye-off' : 'eye-on'}}.svg" matSuffix (click)="hidePassword = !hidePassword">
        </mat-form-field>
        <div class="error" *ngIf="isError">
          <mat-icon>error</mat-icon>{{desktopErrorText}}
        </div>
        <div class="desktop-buttons">
          <div class="button"><button class="btn-login" [class.spinner]="formSubmited" round-button size="large" filled="filled" color="primary" type="submit" id="username" autocomplete="username">
              <span *ngIf="!formSubmited" i18n>log in</span><span *ngIf="formSubmited">&nbsp;</span></button>
          </div>
          <div class="button">
            <button class="btn-open-account" round-button size="large" filled="filled" color="accent" (click)="signup()" type='button' i18n>open account</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- <div id="loader" [style.visibility]="formDelivered ? 'visible' : 'hidden'">
        <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </div> -->
</div>