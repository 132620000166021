import { Apollo } from 'apollo-angular';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { DESKTOP_LOGIN, FULL_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { AuthGuard } from 'src/app/auth.guard';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';

export interface LoginDialogData {

}

@Component({
  template: ''
})
export class LoginDialogRouteComponent implements OnInit {
  constructor(public dialog: MatDialog,
    private router: Router,
    private detectDeviceService: DetectDeviceService) { }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    if (this.detectDeviceService.isDesktop()) {
      const dialogRef = this.dialog.open(LoginDialogComponent, {
        ...DESKTOP_LOGIN,
        backdropClass: "desktopBg",
        data: {},
        panelClass: "desktop-panel"
      });
      dialogRef.afterClosed().subscribe(result => {
        this.router.navigate([dialogRef.componentInstance.redirectUrl]);
      });
    } else {
      const dialogRef = this.dialog.open(LoginDialogComponent, {
        ...FULL_DIALOG_CONFIG,
        backdropClass: "desktopBg",
        panelClass: "desktop-panel",
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        this.router.navigate([dialogRef.componentInstance.redirectUrl]);
      });
    }



  }
}
@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private apollo: Apollo,
    private router: Router,
    public detectDeviceService: DetectDeviceService,
    @Inject(MAT_DIALOG_DATA) public data: LoginDialogData) { }

  public redirectUrl: string = "../"

  closeDialog(redirectUrl: string): void {
    this.redirectUrl = redirectUrl;
    this.router.navigate([redirectUrl]);
    this.dialogRef.close();
  }

  ngOnInit(): void {

  }

}
@Component({
  selector: 'app-login-standalone',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginStandaloneComponent implements OnInit {

  constructor(
    private apollo: Apollo,
    private router: Router,
    private loginStatusService: LoginStatusService,
    private authGuard: AuthGuard,
    public detectDeviceService: DetectDeviceService,
    public minimizeSiteService: MinimizeSiteService,

  ) { }

  public redirectUrl: string = "../"

  closeDialog(redirectUrl: string): void {
    this.redirectUrl = redirectUrl;
    this.router.navigate([redirectUrl]);
    // this.loginStatusService.getLoginStatus().subscribe((status) => {
    //   if (status.isLogged !== LoggedStatus.logged) {
    //     const urlSegments: string[] = redirectUrl.split('/').filter(segment => segment !== '');
    //     const urlConfig = this.router.config.find(conf => conf.path === urlSegments[0] ? urlSegments[0] : '');
    //
    //     const canActivate = urlConfig?.canActivate ? false : true;
    //     const canLoad = urlConfig?.canLoad ? false : true;
    //
    //     this.redirectUrl = (!canActivate || !canLoad) ? '/' : redirectUrl;
    //     this.router.navigate([this.redirectUrl]).then(() => {
    //
    //     });
    //
    //   } else {
    //     this.redirectUrl = redirectUrl;
    //     this.router.navigate([this.redirectUrl]);
    //   }
    // })
  }

  ngOnInit(): void {
    this.minimizeSiteService.minimizePage();

  }

  ngOnDestroy(): void {
    this.minimizeSiteService.restorePage();
  }

}
