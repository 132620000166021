import { environment as common } from './environment.playbison.firebase';


export const environment = {
  ...common,
  production: false,
  enableServiceWorker: false,
  apiUrl: 'https://api-test.playbison.com',
  walletUrl: 'https://api-test.playbison.com',
  paymentUrl: 'https://api-test.playbison.com',
  uiBackend: 'https://prizeengine.playbison.com',
  // uiBackend: 'http://localhost:8000',
  fallbackLanguage: "de-AT",
  isDeploymentConfig: true,
  affiliateProgram: 'https://bison.partners',
  cmsDraftToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NzAyNDQ5MTYsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2xiYXNwMGhjMDU1NDAxdWYzMmpzNGMyai9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiYjhmMDYwOGMtMDRlMC00NTJmLWFlNzUtMzNlNGMzNDgwNDgwIiwianRpIjoiY2tuaXJzaW9xMmljYjAxeG5hOXhjMWpuNiJ9.QW8yHQBZmg7M3OryH19Qap0u_YzKTj7oe_-fjhT33C20B0MlaQ_FYo-BEbh4xs1PJOpR0VlVw79pZPYBnLkiqCgaSUSv2066KUX_0LsRAqHczjs-TafbMkJ1Ss8v8N4wrWY3eIjyRKg8c3YZZaWcktp0wEfNIJJjyWRxVkp0RkwJ7YhV1AYIcCRTnx07XbdbluNrAuMK85b8abVhyw0cTQr9BcNsYu8BgkmNGWEGRfHz50tJyLf2Y6GYK6I0ic-vZNgfL__g5wVdSXpV2GA_QPwdaYCYNX99aFfZQXhRgAOXffrlsGlL5XiKm7OTj3TtY1QakoS_2m3oGh9ahyChBjVrEK1nZX_ATZU64uK4yRwo236hjO2h7EZfnHFMxRfdfe9UEZGnLSvTjvVyE1GmIXd4SAGnMLjs7G1hvJhtuH-mK9h_BiHYXh2G8dAVZ_5eEVrDL8BAKe-SB15VYKKvSDOQuOM2KOF-xzqvHWnwi77J_u8gYULuZeVfUz6ILgBk51eydR-SHm6pT6_-33mk2pT2OZqoa-89g_xL3qlsr4Tn4sjg7AQzc8rWdNxjp0NoEwisbOflDTDqKNo0JvHfYmqO1QbVG0p-n2rp1DOF1DsGw5yisucmu11FthgaX1pS_Pn93__11aFANLx7XZqaAO0gcEKbr9Ym5ArqHMUV5K8",
  baseCurrency: "EUR",
  enableTournamentsDisplay: true,
  enablePaymentIQ: true,
  chatToken: "b7240bde-642b-43b2-b46e-ab5af022f1cd",
  cmsApi: 'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clbasp0hc055401uf32js4c2j/master',
  millApi: 'https://api-tma-stg.themill.tech/gql',
  millBrandId: 'lc',
  firebaseConfig: {
    apiKey: "AIzaSyDtD8Zb6x1zTbLi8PBH3SjDq6ORtkoafUE",
    authDomain: "for-fun-338904.firebaseapp.com",
    projectId: "for-fun-338904",
    storageBucket: "for-fun-338904.appspot.com",
    messagingSenderId: "736138600386",
    appId: "1:736138600386:web:dbef0b35eabc21cc20c05b",
    measurementId: "G-XG9ZNQ4BNY"
  },
  paymentIqConfig: {
    url: 'https://test-api.paymentiq.io/paymentiq/api',
    mid: 100624998,
    sessionIdName: 'forfun_ses',
    userPrefix: 'user'
  },
  checkSessionInterval: 5000,
  checkPopularGamesUsersInterval: 30000,
  checkInboxInterval: 30000,
  minBalanceThresholds: {
    USD: 10,
    EUR: 10,
    GBP: 10,
    PLN: 20,
    HUF: 1500,
    INR: 500,
    CAD: 30,
    AUD: 32,
    NZD: 35,
    ZAR: 150,
    NOK: 100
  },
  defaultDepositValues: {
    PLN: [50, 100, 200, 500, 1000],
    USD: [20, 50, 100, 200, 500],
    EUR: [20, 50, 100, 200, 500],
    GBP: [20, 50, 100, 200, 500],
    HUF: [5000, 10000, 20000, 50000, 100000],
    INR: [500, 1000, 2000, 5000, 10000],
    CAD: [20, 50, 100, 200, 500],
    AUD: [20, 50, 100, 200, 500],
    NZD: [20, 50, 100, 200, 500],
    ZAR: [150, 300, 500, 1000, 2000, 5000],
    NOK: [200, 500, 1000, 2000, 5000]
  }
};
