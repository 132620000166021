<div *ngIf="!isMaintenance else maintenance" class="full-wrapper" [ngClass]="(detectDeviceService.isDesktop()) ? 'desktop': 'mobile'" #mainbackground>

  <div class="body-wrapper" [ngClass]="(detectDeviceService.isDesktop()) ? 'desktop': 'mobile'">
    <ng-container *ngIf="!detectDeviceService.isDesktop() else desktop">
      <ng-container *ngIf="isRegularPage else gamemode">
        <mat-sidenav-container class="top-menu-container" (backdropClick)="close()">
          <mat-sidenav #sidenav mode="over" fixedInViewport="true" disableClose>
            <app-top-menu (closeNav)="close()" [isVisible]="isOpened"></app-top-menu>

          </mat-sidenav>
          <mat-sidenav-content>
            <div class="main-container-content-wrapper">
              <div class="top-container-wrapper">
                <div class="top-container mobile">
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="top-menu">
                    <app-top-menu-button (clicked)="topClicked($event)"></app-top-menu-button>
                    <div class="flex-spacer"></div>
                    <div fxHide.gt-md fxFlexAlign="center" class="top-logo" fxLayoutAlign="start start">
                      <a [routerLink]="['/']"><img src="{{licenceService.getKey('logo')}}" alt="logo-green" /></a>
                    </div>
                    <div class="flex-spacer"></div>
                    <div class="profile-wrapper">
                      <app-user-profile></app-user-profile>
                    </div>
                    <div class="auth-wrapper">
                      <app-main-auth></app-main-auth>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-wrapper">
                <router-outlet></router-outlet>
              </div>
              <app-footer *ngIf="isRegularPage"></app-footer>
              <app-bottom-toolbar *ngIf="!displayGamificationProgress else gamificationProgress"></app-bottom-toolbar>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </ng-container>
    </ng-container>
    <ng-container #globalNotifications></ng-container>
  </div>
</div>
<img *ngIf="!!pixelTrackingUrl()" [src]="pixelTrackingUrl()" width="1" height="1" alt="" />
<app-loader></app-loader>

<ng-template #gamemode>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #maintenance>
  <div class="maintenance" fxLayoutAlign="center center" fxLayout="row" *ngIf="detectDeviceService.isDesktop() else mobile">
    <div>
      <img src="assets/maintanance/PlayBison-Maintenance-Mode.png" alt="maintenance-logo" />
    </div>
    <div>
      <div class="msg" i18n>Hold your horses!<br/> Bison needs a moment to breathe.</div>
      <div class="msg" i18n>We'll be back soon with a wild dose of fun!</div>
    </div>
  </div>
</ng-template>

<ng-template #mobile>
  <div class="maintenance" fxLayoutAlign="center center" fxLayout="column">
    <div>
      <div class="msg" i18n>Hold your horses!<br/> Bison needs a moment to breathe.</div>
    </div>
    <div>
      <img src="assets/maintanance/PlayBison-Maintenance-Mode.png" alt="maintenance-logo" />
    </div>
    <div>
      <div class="msg" i18n>We'll be back soon with a wild dose of fun!</div>
    </div>
  </div>
</ng-template>



<ng-template #desktop>
  <app-desktop-front-page></app-desktop-front-page>
  <!-- <router-outlet></router-outlet> -->
</ng-template>

<ng-template #gamificationProgress>
  <app-gamification-progress></app-gamification-progress>
</ng-template>
